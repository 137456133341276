
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useState } from 'react';

const SummaryDiff = (props) => {
    
    const [diffExpanded, setDiffExpanded] = useState(true);
    const toggleDiffExpander = () => {
      setDiffExpanded(!diffExpanded)
    }
    const { cmdbGroupSummaryEvent } = props
    if (cmdbGroupSummaryEvent?.length === 0) {
        return ""
    }
    
    return <Box sx={{ pb: 2 }}>
        <Accordion expanded={diffExpanded} onChange={toggleDiffExpander} sx={{ backgroundColor: '#f5f5f5' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Summary Diff</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'white', px: 0, py: 0 }}>
                <Typography>
                    <iframe title="Summary Diff"
                        width="100%"
                        height="200px"
                        style={{ border: '1px solid black', padding: '5px' }}
                        srcDoc={cmdbGroupSummaryEvent} />
                </Typography>
            </AccordionDetails>
        </Accordion>
    </Box>
}

export default SummaryDiff;