import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { interfaceThroughputBandwidth } from '../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../context/UserContext';
import { useHealthContext } from '../userContextHealth';
import MainChart from './MainChart';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 590,
}));

const useStyles = makeStyles({
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 635,
    },
});

const MainThroughputBandwidth = ({ interfaceExpanded, selectedInterface, identifier }) => {
    const classes = useStyles();
    const { customerId } = useContext(UserContext);
    const { healthDashState, getStartEnd } = useHealthContext();
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);
    const [response, setResponse] = useState(false);
   

    const fetchThroughputBandwidth = async () => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            interface: selectedInterface.name,
            device_type: identifier,
        };

        try {
            setChartLoading(true);
            const res = await interfaceThroughputBandwidth(
                customerId,
                // healthDashState.selectedDevice.r_dvc_serial_number,
                payload
            );

            const downloadData = res?.map(item => item.in_octet);
            const uploadData = res?.map(item => item.out_octet);
            const intStatusData = res?.map(item => item.int_status === 'up(1)' ? 1 : 0);

            const formattedXAxisData = res.map(item => {
                const date = new Date(item.timestamp * 1000);
                const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                return `${timeString}\n${dateString}`;
            });

            setSeriesData([
                { name: 'InOctet', data: downloadData },
                { name: 'OutOctet', data: uploadData },
                { name: 'Status', data: intStatusData, type: 'line', yAxisIndex: 1 }
            ]);
            setXAxisData(formattedXAxisData);
            setResponse(true);
        } catch (err) {
            console.error(err);
            setResponse(false);
        } finally {
            setChartLoading(false);
        }
    };

    useEffect(() => {
        if (interfaceExpanded && selectedInterface) {
            fetchThroughputBandwidth();
        }
    }, [interfaceExpanded, customerId, identifier, healthDashState.timeRange, getStartEnd, selectedInterface]);

    return (
        <Paper sx={{ padding: '10px' }} variant="outlined">
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12}>
                    <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                        Throughput Bandwidth
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    {seriesData?.length > 0 && xAxisData?.length > 0 && (
                        <MainChart seriesData={seriesData} xAxisData={xAxisData} />
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
export default MainThroughputBandwidth;

