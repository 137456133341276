import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const approveDeviceSummaryChange = async (id, cmdbDeviceSummaryUuid, data) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${cmdbDeviceSummaryUuid}/approve`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const unApproveDeviceSummaryChange = async (id, cmdbDeviceSummaryUuid, data) => {
  const newPromise = modem.post(`${id}/cmdb/workflow/${cmdbDeviceSummaryUuid}/unapprove`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const holdUntillDeviceSummaryChange = async (id, cmdbDeviceSummaryUuid, data) => {
  const newPromise = modem.post(`${id}/cmdb/workflow/${cmdbDeviceSummaryUuid}/hold-untill`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const CMDBSkipPause = async (id, uniqueIdentifier) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${uniqueIdentifier}/pause`).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const CMDBUnskipResume = async (id, uniqueIdentifier) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${uniqueIdentifier}/resume`).then((response) => {
    return response.data.data;
  });
  return newPro;
};


export const pauseUpDown = async (id, uniqueIdentifier) => {  
  const newPro = modem.post(`/${id}/network-devices/${uniqueIdentifier}/pause-up-down`).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const resumeUpDown = async (id, uniqueIdentifier) => {
  const newPro = modem.post(`/${id}/network-devices/${uniqueIdentifier}/resume-up-down`).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const groupApproveAllChange = async (id, uuid, data) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${uuid}/approve-group`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const groupUnApproveAllChange = async (id, uuid, data) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${uuid}/unapprove-group`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const groupHoldUntillAllChange = async (id, uuid, data) => {
  const newPro = modem.post(`/${id}/cmdb/workflow/${uuid}/hold-untill-group`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getIgnoreRule = async (id, payload) => {
  const newPro = modem.post(`${id}/cmdb-ignore-rules`, payload).then((response) => {
    return response.data;
  });
  return newPro;
};


/**
 * 
 * @param {*} id 
 * @param {*} uuid
 * @returns Promise
 */
// export const getIgnoreRule = async (id, uuid, payload) => {
//   const newPro = modem.get(`/${id}/cmdb-ignore-rules/${uuid}`,payload).then((response) => {
//     return response.data.data;
//   });
//   return newPro;
// };
/**
 * 
 * @param {*} id 
 * @param {*} data =  {
    "dcir_device_type": "FIREWALL",
    "dcir_rule_type": "LINES_BETWEEN",
    "dcir_rule_description": "Ignore ceritificate strings1",
    "dcir_start_with": "q1",
    "dcir_ends_with": "-----111",
    "dcir_contains": null,
    "dcir_regex": null
}
 * @returns Promise
 */
export const addIgnoreRule = async (id, data) => {
  const newPro = modem.put(`${id}/cmdb-ignore-rules`, data).then((response) => {
    return response.data;
  });
  return newPro;
};


/**
 * 
 * @param {*} id = customer id
 * @param {*} uuid = rule uuid
 * @param {*} data =  {
    "dcir_device_type": "FIREWALL",
    "dcir_rule_type": "LINES_BETWEEN",
    "dcir_rule_description": "Ignore ceritificate strings1",
    "dcir_start_with": "q1",
    "dcir_ends_with": "-----111",
    "dcir_contains": null,
    "dcir_regex": null
}
 * @returns Promise
 */
export const updateIgnoreRule = async (id,uuid, data) => {
  const newPro = modem.patch(`/${id}/cmdb-ignore-rules/${uuid}`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};


/**
 * 
 * @param {*} id = customer id
 * @param {*} uuid = rule uuid
 * @returns Promise
 */
export const deleteIgnoreRule = async (id,uuid) => {
  const newPro = modem.delete(`/${id}/cmdb-ignore-rules/${uuid}`).then((response) => {
    return response.data.data;
  });
  return newPro;
};