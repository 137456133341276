// import 'chonky/style/main.css';
import {
    Box,
    LinearProgress,
  } from '@mui/material';
  import {
    ChonkyActions,
    // FileArray,
    FileBrowser,
    // FileData,
    FileList,
    FileNavbar,
    FileToolbar,
    setChonkyDefaults
  } from 'chonky';
  import { ChonkyIconFA } from 'chonky-icon-fontawesome';
  import path from 'path-browserify';
  import React, { useCallback, useEffect, useState } from 'react';
  import { generatePath, useLocation, useNavigate } from 'react-router-dom';
  import { BUCKET_NAME, fetchS3BucketContents } from '../../../API/S3/index';
  import CMDBDialog from '../CMDBDialog';
  
  
  setChonkyDefaults({ iconComponent: ChonkyIconFA });
  
  // --------------------------------------------------------------------------
  const useNavigateParams = () => {
    const navigate = useNavigate();
  
    return (url, params) => {
      const path = generatePath(':url', {
        url,
        queryString: params,
      });
      navigate(path);
    };
  };
  // ----------------------------------------------------------------------
  
  export const ChonkyExpForTable = (props) => {
    const { filePath } = props;
    const [error, setError] = useState(null);
    const [folderPrefix, setKeyPrefix] = useState(props.filePath);
    const [files, setFiles] = useState([]);
    const [readFileDialog, setReadFileDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigateParams();
    // const { customerName, locationName, deviceType, deviceIp, all } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
  
  
    useEffect(() => {
      setLoading(true);
      fetchS3BucketContents(BUCKET_NAME, folderPrefix)
        .then((res) => {
          setLoading(false);
          setFiles(res);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    }, [folderPrefix, setFiles, location]);
  
    const folderChain = React.useMemo(() => {
      let folderChain;
      if (folderPrefix === '/') {
        folderChain = [];
      } else {
        let currentPrefix = '';
        folderChain = folderPrefix
          .replace(/\/*$/, '')
          .split('/')
          .map((prefixPart) => {
            currentPrefix = currentPrefix ? path.join(currentPrefix, prefixPart) : prefixPart;
            return {
              id: currentPrefix,
              name: prefixPart,
              isDir: true,
            };
          });
      }
      folderChain.unshift({
        id: '/',
        name: BUCKET_NAME,
        isDir: true,
      });
      return folderChain;
    }, [folderPrefix]);
  
    const handleFileAction = useCallback(
      (data) => {
        if (data.id === 'mouse_click_file' && data.payload.clickType === 'double' && !data.payload.file.isDir) {
          setSelectedFile(data.payload);
          setReadFileDialog(true);
        }
        if (data.id === ChonkyActions.OpenFiles.id) {
          if (data.payload.files && data.payload.files.length !== 1) return;
          if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
          const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
          const newPath = `${data.payload.targetFile.id.replace(/\/*$/, '')}`;
          navigate(newPath);
          setKeyPrefix(newPrefix);
        }
      },
      [setKeyPrefix, setReadFileDialog, setSelectedFile]
    );
  
    const handleDoubleClicks = (event) => {
      if (event.payload.clickType === 'double') {
        setReadFileDialog(true);
      }
    };
  
    // --------------------------------------------------------------------------
  
    return (
      <>
        {loading && <LinearProgress />}
        <Box sx={{ width: '100%', padding: '18px' }}>
          <div style={{ height: '600px' }}>
            <FileBrowser
              files={files}
              instanceId={'cmdb-explorer'}
              folderChain={folderChain}
              onFileAction={handleFileAction}
              defaultFileViewActionId={ChonkyActions.EnableListView.id}
            >
              <FileNavbar />
              <FileToolbar />
              <FileList />
            </FileBrowser>
            {readFileDialog && (
              <CMDBDialog
                openDialog={readFileDialog}
                selectedFile={selectedFile}
                handleClose={() => setReadFileDialog(false)}
              />
            )}
          </div>
        </Box>
      </>
    );
  };
  