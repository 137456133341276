import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  ContentCopy,
  Refresh,
  Search,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getEventsNetworkUpDown } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext';
import TooltipRenderer from '../../../utils/TooltipRenderer';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  downHigh: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  criticalChip: {
    minWidth: '5vw',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  progressStyle: {
    position: 'absolute',
    left: 27,
    top: 22,
  },
});
// ----------------------------------------------------------------

const AlertsTable = (props) => {
  const classes = useStyles();
  const { locationData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);

  // ----------------------------------------------------------------------

  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');



  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    }
    if (isDesktop) {
      return '20px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }


  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };


// ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-inventory-device-up/down'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
        };
        delete payload.context;
        getEventsNetworkUpDown(customerId, payload).then((inventoryData) => {
          let lastRow = -1;
          if (inventoryData.total <= _params.endRow) {
            lastRow = inventoryData.total;
          }
          if (inventoryData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(inventoryData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch(() => {
            setRefreshLoading(false);
            params.failCallback();
          });
      },
    });
  }, [customerId, query]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: false,
      suppressMenu: true,
      filter: false,
    };
  }, []);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnHeaders = [
    {
      headerName: 'Event Time',
      colId: 'updated_at',
      width: 220,
      // pinned: 'left',
      filter: false,
      sortable: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellStyle: responsiveCellStyle,

      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.data?.created_at;
      },
      valueGetter: (params) => {
        if (params.data?.created_at === undefined) {
          return '';
        }
        return params.data?.created_at;
      },
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      // pinned: 'left',
      sortable: false,
      width: 200,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.network_device1?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.network_device1?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRenderer(params?.data?.network_device1?.r_dvc_mgmt_ip, params?.data?.network_device1?.r_dvc_mgmt_type)}

                <CopyToClipboard
                  text={params?.data?.network_device1?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.network_device1?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return null;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      // pinned: 'left',
      sortable: false,
      width: 200,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.location?.r_location_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      // pinned: 'left',
      sortable: false,
      width: 200,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.r_dvc_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Criticality',
      colId: 'r_dvc_up_down',
      width: 200,
      sortable: false,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_critical_level === 'HIGH') {
          return (
            <div className={classes.downHigh}>
              <Chip label="Critical" color="error" className={classes.criticalChip} />
            </div>
          );
        }
        if (params?.data?.r_critical_level === 'LOW') {
          return <Chip label="Info" color="warning" className={classes.criticalChip} />;
        }
        return null;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Current Status',
      colId: 'r_dvc_up_down1',
      // pinned: 'right',
      sortable: false,
      width: 200,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.network_device1?.r_dvc_up_down?.toLowerCase() === 'down') {
          // let since = '';
          // if (params?.data?.network_device?.r_dvc_last_down_ts) {
          //   since = timeSince(params?.data?.network_device?.r_dvc_last_down_ts * 1000);
          //   since = `Since ${since}`;
          // }
          return (
            <div className={classes.downHigh}>
              <Chip label="Down" variant="outlined" avatar={<ArrowCircleDownOutlined style={{ color: '#f44336' }} />} />
            </div>
          );
        }
        if (params?.data?.network_device1?.r_dvc_up_down?.toLowerCase() === 'up') {
          return (
            <div className={classes.downHigh}>
              <Chip label="Up" variant="outlined" avatar={<ArrowCircleUpOutlined style={{ color: '#4caf50' }} />} />
            </div>
          );
        }
        return null;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Details',
      colId: 'r_event_notified',
      filter: false,
      sortable: false,
      width: 200,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_event_notified === 1) {
          return (
            <div style={{ display: 'flex', alignItems: 'left', gap: 2, marginTop: '10px' }}>
              {params?.data?.r_critical_level === 'HIGH' ? (
                <Typography sx={{ fontSize: getFontSize(), 
                fontFamily: 'Arial', fontWeight: '400' }}>Device went down</Typography>
              ) : (
                <Typography sx={{ fontSize: getFontSize(), 
                fontFamily: 'Arial', fontWeight: '400' }}>Device came up</Typography>
              )}
              <div style={{ paddingLeft: '9px' }}>

                <Typography sx={{fontSize: getFontSize(),
            }}>Mail sent</Typography>
              </div>
            </div>
          );
        }
        if (params?.data?.r_event_notified === 0) {
          return (
            <div style={{ display: 'flex', alignItems: 'left', gap: 2, marginTop: '10px' }}>
              {params?.data?.r_critical_level === 'HIGH' ? (
                <Typography sx={{ fontSize: getFontSize(),
               fontFamily: 'Arial', fontWeight: '400' }}>Device went down</Typography>
              ) : (
                <Typography sx={{ fontSize: getFontSize(), 
                fontFamily: 'Arial', fontWeight: '400' }}>Device came up</Typography>
              )}
            </div>
          );
        }
        return null;
      },
      cellStyle: responsiveCellStyle,
    },
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi.refreshInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem(
      'nms-network-inventory-device-up/down',
      JSON.stringify(mapColumnDefinitionToStore(columnHeaders))
    );
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-inventory-device-up/down',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  // ----------------------------------------------------------------------
  return (
    <Card>
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
          >
            <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                Device Up/Down alerts
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <TextField
                focused
                autoFocus
                size="small"
                type="text"
                value={query}
                name='new-password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                onChange={handleQueryChange}
                placeholder="Search devices"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    openDialog={openColSettingsDialog}
                    handleClose={() => setOpenColSettingsDialog(false)}
                    columnDefs={columnDefs}
                    gridApi={gridApi}
                    resetColumnSetting={resetColumnSetting}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />

      {refreshLoading && <LinearProgress />}
      <Divider />

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData,
          }}
          onDragStopped={onDragStopped}
        />
      </Box>
    </Card>
  );
};

AlertsTable.propTypes = {
  locationData: PropTypes.array.isRequired,
};

export default AlertsTable;
