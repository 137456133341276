// /* eslint-disable */
// import { useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const formatBytes = (bytes) => {
//   if (bytes >= 1e6) {
//     return (bytes / 1e6)?.toFixed(2) + ' MB';
//   } else if (bytes >= 1e3) {
//     return (bytes / 1e3)?.toFixed(2) + ' KB';
//   } else {
//     return bytes?.toFixed(2) + ' B';
//   }
// };

// const MainMemoryFreeChart = ({ seriesData, xAxisData }) => {
//   const reversedXAxisData = [...xAxisData].reverse();
//   const reversedSeriesData = seriesData.map(series => ({
//       ...series,
//       data: [...series.data].reverse(),
//   }));
//   const chartOptions = {
//     chart: {
//       type: 'area',
//       height: 260,
//       toolbar: { show: false },
//     },
//     annotations: {
//       yaxis: [{
//         y: 30,
//       }],
//     },
//     stroke: {
//       curve: 'smooth',
//       width: 2,
//     },
//     colors: ['#ffcc80'],
//     dataLabels: {
//       enabled: false,
//       dropShadow: { enabled: true }
//     },
//     tooltip: {
//       shared: false,
//       y: {
//         formatter: (value) => formatBytes(value) + '/s',
//       },
//       x: {
//         show: false,
//       },
//     },
//     legend: {
//       show: true,
//       position: 'bottom',
//       horizontalAlign: 'left',
//       showForSingleSeries: true,
//       formatter: (val, opts) => {
//         const total = opts.w.globals.series[opts.seriesIndex].reduce((acc, value) => acc + value, 0);
//         const overallPercentage = (total / opts.w.globals.series[opts.seriesIndex].length).toFixed(2) + '%';
//         return (
//           `<div>
//             <div> ${val}  Current: ${overallPercentage}</div>
//           </div>`
//         );
//       },
//       offsetX: 0.5,
//       markers: {
//         width: 15,
//         height: 7,
//         strokeWidth: 0,
//         radius: 12,
//         offsetX: 0,
//         offsetY: 0
//       },
//     },
//     xaxis: {
//       categories: reversedXAxisData,
//       type: 'datetime',
//       labels: {
//         hideOverlappingLabels: true,
//         formatter: function (value) {
//           const date = new Date(value * 1000);
//           const hours = date.getHours();
//           const minutes = date.getMinutes();
//           const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//           return timeString;
//         },
//       }
//     },
//     yaxis: {
//       forceNiceScale: true,
//       labels: {
//         formatter: (value) => formatBytes(value) + '/s',
//       },
//     },
//   };

//   return (
//     <>
//     {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
//     </>
//   );
 
// };

// export default MainMemoryFreeChart;


import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MainMemoryFreeChart = ({ seriesData, xAxisData }) => {
  const reversedXAxisData = [...xAxisData].reverse();
  const reversedSeriesData = seriesData.map(series => ({
    ...series,
    data: [...series.data].reverse(),
  }));

  const chartOptions = {
    chart: {
      type: 'area',
      height: 260,
      toolbar: { show: false },
    },
    annotations: {
      yaxis: [{
        y: 30,
      }],
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#ffcc80'],
    dataLabels: {
      enabled: false,
      dropShadow: { enabled: true }
    },
    tooltip: {
      shared: false,
      y: {
        formatter: value => `${value.toFixed(2)}%`, 
      },
      x: {
        formatter: (value) => {
          const date = new Date(value);
          let hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; 
          const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
          const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
          return `${formattedTime}\n${formattedDate}`;
        }
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      showForSingleSeries: true,
      formatter: (val, opts) => {
        const total = opts.w.globals.series[opts.seriesIndex].reduce((acc, value) => acc + value, 0);
        const overallPercentage = (total / opts.w.globals.series[opts.seriesIndex].length).toFixed(2) + '%';
        return (
          `<div>
            <div>${val} Current: ${overallPercentage}</div>
          </div>`
        );
      },
      offsetX: 0.5,
      markers: {
        width: 15,
        height: 7,
        strokeWidth: 0,
        radius: 12,
        offsetX: 0,
        offsetY: 0
      },
    },
    xaxis: {
      categories: reversedXAxisData,
      type: 'datetime',
      labels: {
        hideOverlappingLabels: true,
        formatter: function (value) {
          const date = new Date(value);
          let hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 || 12;
          const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
          const dateString = `${date.getDate()}/${date.getMonth() + 1}`;
          return `${timeString} ${dateString}`;
        },
      }
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: value => `${value.toFixed(2)}%`, // Display percentage
      },
    },
  };

  return (
    <>
      {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
    </>
  );
};

export default MainMemoryFreeChart;
