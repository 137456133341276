import { Card, CardHeader, CardContent, IconButton, Skeleton, Tooltip, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ReactApexChart from 'react-apexcharts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState, useContext } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../../chart';
import cardStyles from '../../../styles/cardsstyles';
import { UserContext } from '../../../context/UserContext';
import { getDeviceByType } from '../../../API/NetworkDevices/networkDevices';
import NoDataComponent from '../../NoDataComponent';
import { toCamelizeWords } from '../../../utils/formatString';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    height: 400,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------

const DevicesByTypeChart = (props) => {
  const { locationData, setShowGraph, showGraph, openedOnce, selectDeviceType } = props;
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);

  const fetchDeviceByType = async () => {
    const locations = locationData;
    getDeviceByType(customerId, locations)
      .then((res) => {
        const toArray = Object.keys(res)
          .map((item) => ({ x: item, y: res[item] }))
          // .map((item) => ({ x: toCamelizeWords(item), y: res[item] }))
          .sort((a, b) => b.y - a.y);
        setLabels(toArray.map((x) => x.x));
        setSeries(toArray);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (openedOnce) {
      fetchDeviceByType();
    }
  }, [customerId, locationData, openedOnce]);

  const chartLabels = labels;

  const options = {
    labels: chartLabels,
    chart: {
      type: 'bar',
      height: 'auto',
      width: '100%',
      padding: '0px',
      toolbar: { show: false },
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
        },
        dataPointSelection: (event, chartContext, config) => {
          const selectedIndex = config.dataPointIndex;
          if (config.dataPointIndex === selectedIndex) {
            const val = config.w.config.labels[selectedIndex];
            const tableNode = document.getElementById("ndTable")
            let scrollTo= 700
            if (tableNode){
              scrollTo=tableNode.offsetTop-document.getElementById("topBar")?.offsetHeight
            }
            selectDeviceType(val);
            window.scrollTo({top:scrollTo, left:0, behavior:"smooth"});
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        columnWidth: '70%',
        barHeight: '70%',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: undefined,
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {

          position: 'top',
          maxItems: 100,
          hideOverflowingLabels: true,
          orientation: 'horizontal',
 

          total: {
            enabled: false,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#373d3f',
              fontSize: '12px',
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: { enabled: false },
      
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        title: {
          formatter: (val, opts) => {
            return toCamelizeWords(opts.w.globals.labels[opts.dataPointIndex]);
          },
        },
        show: false,
      },
      x: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value)=>{
          return toCamelizeWords(value);
        }
      },
    },
    xaxis: {
      labels: {
        formatter: (value)=>{
          return value;
        }
      }
    },
    
    series: [
      {
        data: series,
      },
    ],
  };
  return (
    <>
      <Card elevation={10}>
        <CardHeader
          sx={{ py: 2, px: 2 }}
          color="text.error"
          title={'Devices by type'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
          action={
            <IconButton onClick={() => setShowGraph(!showGraph)} className={classes.vendorChartActionArrowButton}>
              {showGraph ? (
                <KeyboardArrowUpIcon sx={{ fontSize: '1.8rem' }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ fontSize: '1.8rem' }} />
              )}
            </IconButton>
          }
        />
        {showGraph && (
          <CardContent sx={{ pt: 0, pb: 0 }}>
            {series?.length === 0 && !chartLoading ? (
              <Box className={classess.noDataContainer}>
                <NoDataComponent noDataFoundText="Devices by type" />
              </Box>
            ) : (
              <ChartWrapperStyle dir="ltr">
                {chartLoading ? (
                  <Skeleton animation="wave" variant="rectangular" className={classess.skeletonLoader} />
                ) : (
                  <ReactApexChart height={419} options={options} series={options.series} type="bar" />
                )}
              </ChartWrapperStyle>
            )}
          </CardContent>
        )}
      </Card>
    </>
  );
};
export default DevicesByTypeChart;
