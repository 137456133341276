import { Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getIgnoreRule } from '../../../API/CMDBOperations';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import { toCamelizeWords } from '../../../utils/formatString';
import { WithConfirm } from '../../WithConfirm';
import IgnoreLineDialog from './IgnoreLineDialog';
import ColumnSettingsDialog from './filtersIgnoreTable/ColumnSettingsDialog';
import ActionRender from './renderer/ActionRender';



// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 12,
    top: 20,
  },
}));

// -----------------------------------------------------------------------------------------------------------

const IgnoreTable2 = () => {
  const rowHeight = 80;
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [openIgnore, setOpenIgnore] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);


  // --------------------------------------------------------------------------------------------------------------------

    // ----------------------------------------------------------------------

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');
  
  
  
    const getFontSize = () => {
      if (isLargeDesktop) {
        return '24px';
      } 
      if (isDesktop) {
        return '20px';
      } 
      if (isTablet) {
        return '14px';
      } 
      return '12px';
      }
  
  
    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };
  
  
  // ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-ignore-rule-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-ignore-rule-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      floatingFilter: false,
      suppressMenu: true,
      filter: false,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'no',
      // pinned: 'left',
      width: 70,
      floatingFilter: false,
      editable: false,
      sortable: false,
      cellRenderer: (params) => {
        
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Pattern',
      colId: 'dcir_rule_type',
      width: 170,
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      cellRenderer: (params) => {
        
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.dcir_rule_type) {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', paddingTop: "10px" }}>
              {/* <Typography>{toCamelizeWords(params.data?.dcir_rule_type)}</Typography> */}
              <Typography>{params.data?.dcir_rule_type}</Typography>

            </Box>
          );
        }
        return '';
      },
      // cellStyle:  { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Device type',
      colId: 'dcir_device_type',
      width: 250,
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return (params.data?.dcir_device_type);
      },
      // cellStyle:  { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '100' },
    },
    {
      headerName: 'Details',
      colId: 'details',
      width: 280,
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      cellRenderer: (params) => {
        
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.dcir_rule_type === "LINES_BETWEEN") {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left', paddingTop: "10px" }}>
              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>
                Start word : {params?.data?.dcir_start_with}
              </Typography>

              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>
                End word : {params?.data?.dcir_ends_with}
              </Typography>
            </Box>
          );
        }
        if (params.data?.dcir_rule_type === "LINE_CONTAIN") {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', paddingTop: "10px" }}>
              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>
                String : {params?.data?.dcir_contains}
              </Typography>
            </Box>
          );
        }
        if (params.data?.dcir_rule_type === "REGEX") {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', paddingTop: "10px" }}>
              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>
                Regex : {params?.data?.dcir_regex}
              </Typography>
            </Box>
          );
        }
        return '';
      },
      cellStyle:  { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Description',
      colId: 'dcir_rule_description',
      width: 660,
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      valueGetter: (params) => {
        
        if (params.data === undefined) {
          return '';
        }
        return params.data?.dcir_rule_description;
      },
      cellStyle:  { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Action',
      colId: 'action',
      width: 150,
      // pinned: 'right',
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      cellRenderer: 'actionRender',
      cellStyle: responsiveCellStyle,
    },

  ];

  const components = useMemo(() => ({
    actionRender: ActionRender,
  }), []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);

    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          filterModel: {
            ..._params.filterModel,
            dcir_is_global: {
              filterType: 'text',
              type: '=',
              filter: '0',
            },
          },
          sortModel: {
            ..._params.sortModel,
          },
        };
        delete payload.context;
        const pageCount = params.api.paginationGetCurrentPage() + 1;

        getIgnoreRule(customerId, pageCount, payload)
        .then((rulesData) => {          
          let lastRow = -1;
          if (rulesData?.data?.total) {
            lastRow = rulesData.total;
          }
          
          setTotalItems(rulesData?.data?.total)
          if (rulesData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(rulesData?.data?.data, lastRow);
          setRefreshLoading(false);
        })
        .catch((result) => {
          setRefreshLoading(false);
          _params.failCallback();
        });
      },
    });
  }, [customerId, query]);

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem(
      'nms-ignore-rule-column',
      JSON.stringify(mapColumnDefinitionToStore(columnHeaders)),
    );
    setOpenColSettingsDialog(false);
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const handleOpenAddRuleDialog = () => {
    setOpenIgnore(true);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card id="ndTable">
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  CMDB Ignore rules
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleOpenAddRuleDialog()}
                >
                  Add ignore rule
                </Button>
                {openIgnore && (
                  <IgnoreLineDialog
                    gridApi={gridApi}
                    openDialog={openIgnore}
                    handleCloses={() => setOpenIgnore(false)}
                  />
                )}
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search rules"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={28} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <div>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      gridApi={gridApi}
                      columnDefs={columnDefs}
                      openDialog={openColSettingsDialog}
                      resetColumnSetting={resetColumnSetting}
                      handleClose={() => setOpenColSettingsDialog(false)}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            rowHeight={rowHeight}
            onDragStopped={onDragStopped}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            components={components}
            {...defaultAgGridProps}
            pagination
            rowBuffer={15}
            paginationPageSize={15}
            cacheBlockSize={15}
            cacheOverflowSize={15}
            suppressRowClickSelection
            context={{
              global_keyword: query,
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default WithConfirm(IgnoreTable2);
