import {TestbedInstance, instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getZones = async (id, page, data) => {
  
  const newPromise = modem.get(`${id}/zones?page=${page}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const addZone = async (id, data) => {
  
  const newPromise = modem.post(`${id}/zones`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const deleteZone = async (id, zoneid, data) => {
  const newPromise = modem.delete(`${id}/zones/${zoneid}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const editZone = async (id, zoneid, data) => {
  const newPromise = modem.post(`${id}/zones/${zoneid}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};