
// import { Grid, Paper, Tabs, Tab, CircularProgress } from '@mui/material';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { availabilityStatus } from 'src/API/NetworkDevices/health';
// import { useHealthContext } from '../../../userContextHealth';
// import AvailabilityChart from './AvailabilityChart';
// import { useSearchParams } from 'react-router-dom';

// const AvailabilityPage = ({ availabilityExpanded }) => {
//   const { customerId } = useContext(UserContext);
//   const { getStartEnd } = useHealthContext();
//   const [searchParams] = useSearchParams();
//   const locationUuid = searchParams.get('locationUuid');
//   const identifier = searchParams.get('identifier');

//   const [value, setValue] = useState(0); // State to manage tabs
//   const [seriesData, setSeriesData] = useState([]);
//   const [xAxisData, setXAxisData] = useState([]);
//   const [chartLoading, setChartLoading] = useState(false);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const fetchData = async () => {
//     const data = getStartEnd();
//     const payload = {
//       start: data.start / 1000,
//       end: data.end / 1000,
//       device_type: identifier,
//     };

//     try {
//       setChartLoading(true);
//       const res = await availabilityStatus(customerId, payload);
//       const latencyData = res?.latencies.map(Number);
//       const jitterData = res?.jitterss.map(Number);
//       const packetlossData = res?.packet_losses.map(Number);
//       const upDownData = res?.statuses.map(status => (status ===  1 ? 1 : 0));
//       const formattedXAxisData = res?.timestamps.map(timestamp => {
//         const date = new Date(timestamp * 1000);
//         const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//         const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//         return `${timeString}\n${dateString}`;
//       });

//       let newSeriesData = [];
//       switch (value) {
//         case 0: // Latency
//           newSeriesData = [{ name: 'Latency', data: latencyData }];
//           break;
//         case 1: // Jitter
//           newSeriesData = [{ name: 'Jitter', data: jitterData }];
//           break;
//         case 2: // Packet Loss
//           newSeriesData = [{ name: 'Packet Loss', data: packetlossData.map(val => val.toFixed(0)) }];
//           break;
//         case 3: // Up/Down
//           newSeriesData = [{ name: 'Up/Down', data: upDownData }];
//           break;
//         default:
//           break;
//       }

//       setSeriesData(newSeriesData);
//       setXAxisData(formattedXAxisData);
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setChartLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [availabilityExpanded, value]); // Fetch data when tab changes or availabilityExpanded changes

//   return (
//     <Paper sx={{ padding: '10px' }} variant="outlined">
//       <Grid container spacing={1}>
//         <Grid item lg={12} md={12} sm={12}>
//           <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
//             <Tab label="Latency" />
//             <Tab label="Jitter" />
//             <Tab label="Packet Loss" />
//             <Tab label="Up/Down" />
//           </Tabs>
//         </Grid>
//         <Grid item lg={12} md={12} sm={12}>
//           {chartLoading ? (
//             <CircularProgress />
//           ) : (
//             seriesData.length > 0 && xAxisData.length > 0 && (
//               <AvailabilityChart seriesData={seriesData} xAxisData={xAxisData} tabIndex={value} />
//             )
//           )}
//         </Grid>
//       </Grid>
//     </Paper>
//   );
// };

// export default AvailabilityPage;

import { Grid, Paper, Tabs, Tab, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { availabilityStatus } from 'src/API/NetworkDevices/health';
import { useHealthContext } from '../../../userContextHealth';
import AvailabilityChart from './AvailabilityChart';
import { useSearchParams } from 'react-router-dom';

const AvailabilityPage = ({ availabilityExpanded }) => {
  const { customerId } = useContext(UserContext);
  const {healthDashState, getStartEnd } = useHealthContext();
  const [searchParams] = useSearchParams();
  const locationUuid = searchParams.get('locationUuid');
  const identifier = searchParams.get('identifier');

  const [value, setValue] = useState(0); // State to manage tabs
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [rawData, setRawData] = useState({}); // Store raw data for all tabs
  const [chartLoading, setChartLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Update the series data based on the selected tab
    switch (newValue) {
      case 0: // Latency
        setSeriesData([{ name: 'Latency', data: rawData.latencyData || [] }]);
        break;
      case 1: // Jitter
        setSeriesData([{ name: 'Jitter', data: rawData.jitterData || [] }]);
        break;
      case 2: // Packet Loss
        setSeriesData([{ name: 'Packet Loss', data: (rawData.packetlossData || []).map(val => val.toFixed(0)) }]);
        break;
      case 3: // Up/Down
        setSeriesData([{ name: 'Up/Down', data: rawData.upDownData || [] }]);
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    const data = getStartEnd();
    const payload = {
      start: data.start / 1000,
      end: data.end / 1000,
      device_type: identifier,
    };

    try {
      setChartLoading(true);
      const res = await availabilityStatus(customerId, payload);

      const latencyData = res?.latencies.map(Number);
      const jitterData = res?.jitterss.map(Number);
      const packetlossData = res?.packet_losses.map(Number);
      const upDownData = res?.statuses.map(status => (status ===  1 ? 1 : 0));
      const formattedXAxisData = res?.timestamps.map(timestamp => {
        const date = new Date(timestamp * 1000);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
        return `${timeString}\n${dateString}`;
      });

      setRawData({
        latencyData,
        jitterData,
        packetlossData,
        upDownData,
      });

      setXAxisData(formattedXAxisData);

      // Set initial series data based on the default tab (Latency)
      setSeriesData([{ name: 'Latency', data: latencyData }]);
    } catch (err) {
      console.error(err);
    } finally {
      setChartLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [ healthDashState.timeRange]); 

  return (
    <Paper sx={{ padding: '10px' }} variant="outlined">
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12}>
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            <Tab label="Latency" />
            <Tab label="Jitter" />
            <Tab label="Packet Loss" />
            <Tab label="Up/Down" />
          </Tabs>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          {chartLoading ? (
            <CircularProgress />
          ) : (
            seriesData.length > 0 && xAxisData.length > 0 && (
              <AvailabilityChart seriesData={seriesData} xAxisData={xAxisData} tabIndex={value} />
            )
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AvailabilityPage;
