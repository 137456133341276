// @mui
import { Grid } from '@mui/material';
import { useState } from 'react';
import { minWidth } from '@xstyled/styled-components';
import DevicesByTypeChart from './DevicesByTypeChart';
import EndOfLifeChart from './EndOfLifeChart';
import EndOfSaleChart from './EndOfSaleChart';
import VendorChart from './VendorChart';
import VulnerabilityChart from './VulnerabilityChart';


const InventoryCards = (props) => {
  const { locationData, selectDeviceType, selectVendors} = props;
  const [showGraph, setShowGraph] = useState(true);
  const [openedOnce, setOpenedOnce] = useState(true);
  const toggleShowGraph = () => {
    setOpenedOnce(true);
    setShowGraph(!showGraph);
  };
  return (
    <>
      {/* <Grid sx={{ minWidth: '490px' }} item lg={4} sm={6} xs={12} md={4}>
        <EndOfSaleChart locationData={locationData} devicesData={devicesData} />
      </Grid>
      <Grid sx={{ minWidth: '490px' }} item lg={4} sm={6} xs={12} md={4}>
        <EndOfLifeChart locationData={locationData} devicesData={devicesData} />
      </Grid>
      <Grid sx={{ minWidth: '490px' }} item lg={4} sm={6} xs={12} md={4}>
        <VulnerabilityChart locationData={locationData} devicesData={devicesData} />
      </Grid> */}
      <Grid container item spacing={4}>
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <VendorChart
            showGraph={showGraph}
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph}
            locationData={locationData}
            selectVendors={selectVendors}
          />
        </Grid>
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <DevicesByTypeChart
            showGraph={showGraph}
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph}
            locationData={locationData}
            selectDeviceType={selectDeviceType}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default InventoryCards;
