// /* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { networkHealthMemoryUsageDetailed } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';


// // -------------------------------------

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 260,
// }));

// function formatBytes(bytes) {
//     if (bytes >= 1e6) {
//         return (bytes / 1e6).toFixed(0) + ' MB';
//     } else if (bytes >= 1e3) {
//         return (bytes / 1e3).toFixed(0) + ' KB';
//     } else {
//         return bytes.toFixed() + ' B';
//     }
// }

// // ---------------------------------------


// const MemoryFreeChart = ({overviewExpanded}) => {
//     const { healthDashState, getStartEnd } = useHealthContext()
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     const solveCondition = async() => {
//         if(!healthDashState.selectedDevice || overviewExpanded === false){
//             return '';
//         }
//         fetchMemoryFree();
//     };

//     const fetchMemoryFree = async () => {
//         const data = getStartEnd()
//         const payload = {
//             start: data.start/1000,
//             end: data.end/1000,
//             step: data.step,
//             device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//             device_os: healthDashState.selectedDevice.r_dvc_os
//         }
//         setChartLoading(true);
//         networkHealthMemoryUsageDetailed(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
//             .then((res) => {
//                 setChartLoading(false)
//                 setSeriesData(res?.memory_usages)
//                 setXAxisData(res?.timestamps)
//             })
//             .catch(() => {
//                 setChartLoading(false);
//             });
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, overviewExpanded]);

//     const chartSeries = seriesData;

//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 260,
//             toolbar: { show: false },
//         },
//         annotations: {
//             yaxis: [{
//                 y: 30,
//             }],
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 2,
//         },
//         colors: ['#ffcc80'],
//         dataLabels: {
//             enabled: false,
//             dropShadow: { enabled: true }
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: (value) => formatBytes(value) + '/s',
//             },
//             x: {
//               show: false,
//             },
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             horizontalAlign: 'left',
//             showForSingleSeries: true,
//             formatter: (val, opts) => {
//                 const total = opts.w.globals.series[opts.seriesIndex].reduce((acc, value) => acc + value, 0);
//                 const overallPercentage = (total / opts.w.globals.series[opts.seriesIndex].length).toFixed(2) + '%';
//                 return (
//                     `<div>
//                                 <div> ${val}  Current: ${overallPercentage}</div> 
//                     </div>`
//                 );
//             },
//             offsetX: 0.5,
//             markers: {
//                 width: 15,
//                 height: 7,
//                 strokeWidth: 0,
//                 radius: 12,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         xaxis: {
//             categories: xAxisData,
//             type: 'datetime',
//             labels: {
//                 hideOverlappingLabels: true,
//                 formatter: function (value) {
//                     const date = new Date(value*1000);
//                     const hours = date.getHours();
//                     const minutes = date.getMinutes();
//                     const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//                     return timeString;
//                 },
//             }
//         },
//         yaxis: {
//             forceNiceScale: true,
//             labels: {
//                 formatter: (value) => formatBytes(value) + '/s',
//             },
//         },
//     };


//     return (
//         <>
//         {chartLoading && <LinearProgress />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Memory Free (MB)
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                         <ChartWrapperStyle dir="ltr">
//                             <ReactApexChart series={chartSeries} options={chartOptions} type="area" height={260} />
//                         </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };
// export default MemoryFreeChart;

/* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// // import { networkHealthMemoryUsageDetailed } from '../../../../../../API/NetworkDevices/health';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart'; // Adjust the import path as needed

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//   marginTop: theme.spacing(1),
//   height: 260,
// }));

// const MemoryFreeChart = ({ overviewExpanded, allData }) => {
//   const { healthDashState, getStartEnd } = useHealthContext();
//   const { customerId } = useContext(UserContext);
//   const [seriesData, setSeriesData] = useState([]);
//   const [xAxisData, setXAxisData] = useState([]);
//   const [chartLoading, setChartLoading] = useState(true);

//   const solveCondition = async () => {
//     // if (!healthDashState.selectedDevice || overviewExpanded === false) {
//     //   return;
//     // }
//     fetchMemoryFree();
//   };

//   const fetchMemoryFree = async () => {
//     const data = getStartEnd();
//     // const payload = {
//     //   start: data.start / 1000,
//     //   end: data.end / 1000,
//     //   step: data.step,
//     //   // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
//     //   // device_os: healthDashState.selectedDevice.r_dvc_os
//     // };
//     setChartLoading(true);
//     try {
//       const res = allData;
//       setSeriesData([{ name: 'Memory Free', data: allData?.memory_usages }]);
//       setXAxisData(res?.timestamps.map(timestamp => {
//         const date = new Date(timestamp * 1000);
//         const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//         const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//         return `${timeString}\n${dateString}`;
//       }));
//     } catch (error) {
//       console.error('Error fetching memory free data:', error);
//     } finally {
//       setChartLoading(false);
//     }
//   };

//   useEffect(() => {
//     solveCondition();
//   }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, overviewExpanded, allData]);

//   return (
//     <>
//       {chartLoading && <LinearProgress />}
//       <Paper sx={{ padding: '10px' }} variant="outlined">
//         <Grid container spacing={1}>
//           <Grid item lg={12} md={12} sm={12}>
//             <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//               Memory Free (MB)
//             </Typography>
//           </Grid>
//         </Grid>

//         <Grid item lg={12} md={12} sm={12}>
//           <ChartWrapperStyle dir="ltr">
//             {seriesData?.length > 0 && xAxisData?.length > 0 && <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} />}
//             {/* <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} /> */}
//           </ChartWrapperStyle>
//         </Grid>
//       </Paper>
//     </>
//   );
// };

// export default MemoryFreeChart;

/* eslint-disable */
// import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 260,
// }));

// const MemoryFreeChart = ({ overviewExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     console.log("allData", allData);

//     const solveCondition = async () => {
//         fetchMemoryFree();
//     };

//     const fetchMemoryFree = async () => {
//         const data = getStartEnd();
        
//         setChartLoading(true);
        
//         const convertedArray = allData?.memory_usages.map(Number);
//         const formattedXAxisData = allData?.timestamps.map(timestamp => {
//             const date = new Date(timestamp * 1000);
//             const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//             const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//             return `${timeString}\n${dateString}`;
//         });
        
//         setChartLoading(false);
        
//         setSeriesData([{ "name": 'Memory Free', "data": convertedArray || [] }]);
//         setXAxisData(formattedXAxisData || []);
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, overviewExpanded, allData]);

//     return (
//         <>
//             {chartLoading && <LinearProgress />}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             Memory Free (MB)
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                         {seriesData?.length > 0 && xAxisData?.length > 0 && <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} />}
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default MemoryFreeChart;

import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import MainMemoryFreeChart from '../../../MainCpuChart/MainMemoryFreeChart';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 260,
}));

const MemoryFreeChart = ({ overviewExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(true);

    const solveCondition = async () => {
        fetchMemoryFree();
    };

    const fetchMemoryFree = async () => {
        const data = getStartEnd();
        
        setChartLoading(true);
        
        const convertedArray = allData?.memory_usages.map(Number);
        const formattedXAxisData = allData?.timestamps.map(timestamp => {
            const date = new Date(timestamp * 1000);
            const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
            return `${timeString} ${dateString}`;
        });
        
        setChartLoading(false);
        
        setSeriesData([{ "name": 'Memory Free', "data": convertedArray || [] }]);
        setXAxisData(formattedXAxisData || []);
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, overviewExpanded, allData]);

    return (
        <>
            {chartLoading && <LinearProgress />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Memory Free (%)
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        {seriesData?.length > 0 && xAxisData?.length > 0 && <MainMemoryFreeChart seriesData={seriesData} xAxisData={xAxisData} />}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default MemoryFreeChart;




