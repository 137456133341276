import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useHealthContext } from '../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    // height: 80,
}));

const useStyles = makeStyles({
    headerTextStyle: {
        textAlign: 'center',
        color: '#9e9e9e',
        wordWrap: "break-word"
    },
    subtitleTextStyle: {
        textAlign: 'center',
        color: '#212121',
        wordWrap: "break-word"
    }
});

// ---------------------------------------


const MainOverviewDetails = () => {
    const classes = useStyles();
    const { healthDashState } = useHealthContext()

    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper sx={{ padding: '10px' }} variant="outlined">
                        <ChartWrapperStyle>
                            <Grid container spacing={2} direction="row">
                                <Grid item lg={2.4} md={2.4} sm={2.4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Name
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_name || '------' }
                                    </Typography>
                                </Grid>
                                <Grid item lg={2.4} md={2.4} sm={2.4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Version
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_firmware || '------'}
                                    </Typography>
                                </Grid>
                                <Grid item lg={2.4} md={2.4} sm={2.4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Model
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_model || '------'}
                                    </Typography>
                                </Grid>
                                <Grid item lg={2.4} md={2.4} sm={2.4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Uptime
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_uptime || '------'}
                                    </Typography>
                                </Grid>
                                <Grid item lg={2.4} md={2.4} sm={2.4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Serial Number
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_serial_number || '------'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ChartWrapperStyle>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
export default MainOverviewDetails;
