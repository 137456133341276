
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, CircularProgress, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getLocationsWithoutPagination } from '../../API/Locations/showLocations';
import { UserContext } from '../../context/UserContext';

// -----------------------------------------------------------------------

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -----------------------------------------------------------------------

export default function LocationSelect(props) {
  const { locationData, selectLocation } = props;
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // ----------------------------------------------------------------------------

  const defaultValues = useMemo(() => {
    if (!locationOptions || !locationData || locationData.length === 0) {
      return [];
    }

    const filtered = locationOptions.filter((option) =>
      locationData.includes(option.location_id.toString())
    );

    return filtered;
  }, [locationOptions, locationData]);

  useEffect(() => {
    const loadLocationOptions = async () => {
      try {
        setLoading(true);
        const _locationOptions = await getLocationsWithoutPagination(customerId);
        const sortedOptions = _locationOptions.data.sort((a, b) => {
          if (a.zone?.r_zone_name !== b.zone?.r_zone_name) {
            return a.zone?.r_zone_name.localeCompare(b.zone?.r_zone_name);
          }
          return a.r_location_name.localeCompare(b.r_location_name);
        });
        setLocationOptions(sortedOptions);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    loadLocationOptions();
  }, [customerId]);

  useEffect(() => {
    setSelectedLocations([]);
    selectLocation([]);
  }, [customerId]);
  
  useEffect(() => {
    if (defaultValues.length > 0) {
      setSelectedLocations(defaultValues);
    } 
    // else {
    //   setSelectedLocations([]); 
    // }
  }, [defaultValues,customerId]);
  

  const handleSelectLocation = (event, selectedOptions) => {
    setSelectedLocations(selectedOptions);
    const selectedLocationUids = selectedOptions.map((option) => option.location_id);
    selectLocation(selectedLocationUids);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Autocomplete
        multiple
        size="small"
        limitTags={2}
        loading={loading}
        disableCloseOnSelect
        value={selectedLocations}
        options={locationOptions}
        onChange={handleSelectLocation}
        sx={{ maxWidth: 300, minWidth: 300 }}
        // groupBy={(option) => option.zone?.r_zone_name}
        getOptionLabel={(option) => option.r_location_name}
        isOptionEqualToValue={(option, value) => option.location_id === value.location_id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.r_location_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Locations"
            placeholder="Choose Locations"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

LocationSelect.propTypes = {
  locationData: PropTypes.array.isRequired,
  selectLocation: PropTypes.func.isRequired,
};




