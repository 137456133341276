import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import chatbot from '../Assets/chatbot.png';

const useStyles = makeStyles((theme) => {
  return {
    nodatablock: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '30px',
    },
  };
});

const NoDataComponent = (props) => {
  const classes = useStyles();
  const { noDataFoundImageSrc, noDataFoundText } = props;
  return (
    <Box className={classes.nodatablock}>
      <Typography variant="h5">
        No data found for {noDataFoundText}
      </Typography>
      <img width="100px" height="100px" src={noDataFoundImageSrc || chatbot} alt="img" />
    </Box>
  );
};

export default NoDataComponent;
