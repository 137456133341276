
// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const MainCpuChart = ({ seriesData, xAxisData }) => {
//     // Reverse the xAxisData and seriesData to simulate RTL direction
//     const reversedXAxisData = [...xAxisData].reverse();
//     const reversedSeriesData = seriesData.map(series => ({
//         ...series,
//         data: [...series.data].reverse(),
//     }));

//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 260,
//             toolbar: { show: false },
//         },
//         annotations: {
//             yaxis: [{
//                 y: 30,
//             }],
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 2,
//         },
//         colors: ['#ef9a9a'],
//         dataLabels: {
//             enabled: false,
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: value => `${value?.toFixed(0)}%`,
//             },
//             x: {
//                 formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
//                     const date = new Date(value);
//                     const hours = date.getHours();
//                     const minutes = date.getMinutes();
//                     const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
//                     const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//                     return `${formattedTime}\n${formattedDate}`;
//                 }
//             },
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             horizontalAlign: 'left',
//             showForSingleSeries: true,
//             offsetX: 0.5,
//             markers: {
//                 width: 15,
//                 height: 7,
//                 strokeWidth: 0,
//                 radius: 12,
//                 offsetX: 0,
//                 offsetY: 0,
//             },
//         },
//         xaxis: {
//             categories: reversedXAxisData,
//             type: 'datetime',
//             labels: {
//                 hideOverlappingLabels: true,
//                 formatter(value) {
//                     const date = new Date(value);
//                     const hours = date.getHours();
//                     const minutes = date.getMinutes();
//                     const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
//                     const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//                     return `${formattedTime}\n${formattedDate}`;
//                 },
//             },
//         },
//         yaxis: {
//             forceNiceScale: true,
//             labels: {
//                 formatter: value => `${value.toFixed(0)}%`,
//             },
//         },
//     };

//     return (
//         <div>
//             {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
//         </div>
//     );
// }

// export default MainCpuChart;

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MainCpuChart = ({ seriesData, xAxisData }) => {
    // Reverse the xAxisData and seriesData to simulate RTL direction
    const reversedXAxisData = [...xAxisData].reverse();
    const reversedSeriesData = seriesData.map(series => ({
        ...series,
        data: [...series.data].reverse(),
    }));

    const chartOptions = {
        chart: {
            type: 'area',
            height: 260,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ['#ef9a9a'],
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: false,
            y: {
                formatter: value => `${value?.toFixed(0)}%`,
            },
            x: {
                formatter: (value) => {
                    const date = new Date(value);
                    let hours = date.getHours();
                    const minutes = date.getMinutes();
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                    const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                    return `${formattedTime}\n${formattedDate}`;
                }
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            offsetX: 0.5,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
            },
        },
        xaxis: {
            categories: reversedXAxisData,
            type: 'datetime',
            labels: {
                hideOverlappingLabels: true,
                formatter(value) {
                    const date = new Date(value);
                    let hours = date.getHours();
                    const minutes = date.getMinutes();
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                    const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                    return `${formattedTime}\n${formattedDate}`;
                },
            },
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: value => `${value.toFixed(0)}%`,
            },
        },
    };

    return (
        <div>
            {reversedSeriesData && <ReactApexChart series={reversedSeriesData} options={chartOptions} type="area" height={260} />}
        </div>
    );
}

export default MainCpuChart;
