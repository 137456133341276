import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import StarIcon from '@mui/icons-material/Star';
import { getconfigBackupEventsviaSidebar } from '../../API/S3/ConfigEvents';
import { UserContext } from '../../context/UserContext';
import TooltipRendererForCmdb from '../../utils/TooltipRendererForCmdb';
import { defaultAgGridProps } from '../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../utils/columnDef';
import { toCamelizeWords } from '../../utils/formatString';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';
import ActionRenderer from './renderer/ActionRenderer';
import ConfigRenderer from './renderer/ConfigRenderer';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  downHigh: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  criticalChip: {
    minWidth: '5vw',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  progressStyle: {
    position: 'absolute',
    top: 20,
    left: 14,
  },
});
// ----------------------------------------------------------------

const ConfigChangeBackupTable = (props) => {
  const classes = useStyles();
  const { locationData, deviceTypes, setDeviceTypes } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const locationDataRef = useRef(locationData);

  // ----------------------------------------------------------------------

  useEffect(() => {
    locationDataRef.current = locationData;
  }, [locationData]);

  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');



  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    }
    if (isDesktop) {
      return '20px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }


  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

  // ----------------------------------------------------------------------------------------------------

  useEffect(() => {

    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
        deviceType: deviceTypes,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, deviceTypes, gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-config-backup-table'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
        deviceType: deviceTypes,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        let payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: locationDataRef.current,
          deviceType: _params.context.deviceTypes,
        };
        if (_params.context.deviceType.length > 0) {
          payload = {
            ...payload,
            filterModel: {
              ..._params.filterModel,
              l_dvc_typ_name: {
                filterType: 'text',
                type: 'CONTAINS',
                filter: _params.context.deviceType,
              },
            },
          };
        }
        delete payload.context;
        getconfigBackupEventsviaSidebar(customerId, payload)
          .then((inventoryData) => {
            let lastRow = -1;
            if (inventoryData.total <= _params.endRow) {
              lastRow = inventoryData.total;
            }
            setTotalItems(inventoryData.total);
            if (inventoryData.total === 0) {
              params.api.showNoRowsOverlay();
            }
            _params.successCallback(inventoryData.data, lastRow);
            setRefreshLoading(false);
          })
          .catch((e) => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [customerId, query, deviceTypes]);

  const deleteDeviceTypeSearch = () => {
    setDeviceTypes('');
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const components = useMemo(() => ({
    configRenderer: ConfigRenderer,
    actionRenderer: ActionRenderer,
  }), []);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'id',
      // pinned: 'left',
      width: 90,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      // pinned: 'left',
      width: 200,
      sortable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {

        if (params.data === undefined) {
          return '';
        }
        if (params.data?.network_device?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.network_device?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRendererForCmdb(params)}
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return null;
      },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      // pinned: 'left',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device?.r_dvc_mac_address;
      },
    },
    {
      headerName: 'Unique Identifier',
      colId: 'r_dvc_unique_identifier',
      // pinned: 'left',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_unique_identifier;
      },
      hide: true,
    },

    {
      headerName: 'Location',
      colId: 'l_location_name',
      // pinned: 'left',
      sortable: false,
      width: 280,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.location?.r_location_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: true,
      width: 350,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device?.r_dvc_serial_number;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Host Name',
      colId: 'r_dvc_name',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device?.r_dvc_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      // pinned: 'right',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.network_device?.l_dvc_typ_name);
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Configuration',
      colId: 'configuration',
      // pinned: 'right',
      width: 200,
      filter: false,
      sortable: false,
      cellRenderer: 'configRenderer',
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Actions',
      colId: 'action',
      // pinned: 'right',
      width: 200,
      filter: false,
      sortable: false,
      cellRenderer: 'actionRenderer',
      cellStyle: responsiveCellStyle,
    },
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi.refreshInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-config-backup-table', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem('nms-config-backup-table', JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs)));
  };

  // ----------------------------------------------------------------------
  return (
    <Card id="CMDBTable">
      <CardHeader
        style={{ padding: '10px' }}
        avatar={
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px',
            }}
          >
            <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
              <Chip label={totalItems || 0} />
              {refreshLoading && <CircularProgress size={30} className={classes.progressStyle} />}
              <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                CMDB Inventory
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', gap: 2 }}
            >
              {deviceTypes?.length > 0 ? (
                <Chip label={toCamelizeWords(deviceTypes)} value={deviceTypes} variant="outlined" onDelete={deleteDeviceTypeSearch} />
              ) : (
                ''
              )}
              {/* <IconButton size="small">
                <StarIcon color='success' fontSize="inherit" />
                <Typography>WireLess Controller</Typography>
              </IconButton>
              <IconButton size="small">
                <StarIcon sx={{ color: 'orange' }} fontSize="inherit" />
                <Typography>VC Member</Typography>
              </IconButton> */}
              <IconButton size="small">
                <StarIcon color="info" fontSize="inherit" />
                <Typography>VC Master</Typography>
              </IconButton>
              <TextField
                focused
                autoFocus
                type="text"
                size="small"
                value={query}
                variant="outlined"
                name='new-password'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search devices"
              />
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <IconButton color="primary" onClick={handleRefresh}>
                {refreshLoading ? (
                  <CircularProgress size={27} sx={{ mr: '5px' }} />
                ) : (
                  <Refresh sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    openDialog={openColSettingsDialog}
                    handleClose={() => setOpenColSettingsDialog(false)}
                    columnDefs={columnDefs}
                    gridApi={gridApi}
                    resetColumnSetting={resetColumnSetting}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />

      <Divider />
      {refreshLoading && <LinearProgress />}

      <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          components={components}
          {...defaultAgGridProps}
          context={{
            global_keyword: query,
            location_uuids: locationData,
            deviceType: deviceTypes
          }}
          onDragStopped={onDragStopped}
        />
      </Box>
    </Card>
  );
};

ConfigChangeBackupTable.propTypes = {
  locationData: PropTypes.array.isRequired,
  deviceTypes: PropTypes.string.isRequired,
  setDeviceTypes: PropTypes.func.isRequired,
};

export default ConfigChangeBackupTable;
