import {
  Delete, FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage, Refresh, Search
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Card, CardContent, CardHeader,
  Chip, CircularProgress,
  Divider,
  Grid,
  IconButton, InputAdornment,
  LinearProgress,
  Link,
  Table, TableBody,
  TableCell, TableContainer, TableFooter,
  TableHead, TablePagination, TableRow,
  TextField, Tooltip, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useCallback } from 'react';
import { deleteZone, getZones } from '../../../API/Zones/zones';
import { UserContext } from '../../../context/UserContext';
import NoDataComponent from '../../NoDataComponent';
import { WithConfirm } from '../../WithConfirm';
import MultipleCheckbox from './MultipleCheckbox';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  tableContainer: {
    "&::-webkit-scrollbar": {
      width: 7,
      height: '12px',
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: '#f1f1f1',
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: 'rgb(181, 179, 179)',
      borderRadius: '30px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: '#555'
    }
  },
  cardAvatar: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
    marginLeft: '10px',
  },
  circularprogress: {
    position: 'absolute',
    left: 12,
    top: 18,
  },
  cardActionButtons: {
    height: '40px',
  },
  AvatarBox: {
    alignItems: 'center',
    display: 'flex'
  },
  Avatar: {
    height: 42,
    width: 42,
  },
});
// -----------------------------------------------------------------------------------------------------------
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// -----------------------------------------------------------------------------------------------------------

const ZoneTable = (props) => {
  const { confirm } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState({});
  const { customerId } = useContext(UserContext);
  const [zonesData, setZonesData] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [countLoading, setCountLoading] = useState(false);
  const [deleteButton, showDeleteButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [zonesDataLoading, setZonesDataLoading] = useState(false);

  const fetchZones = useCallback(async () => {
    setZonesDataLoading(true);
    setCountLoading(true);
    const res = await getZones(customerId, page + 1);
    setCountLoading(false);
    setZonesDataLoading(false);
    setZonesData(res);
    setCount(res.total);
    setRowsPerPage(15);
  }, [customerId, page]);

  useEffect(() => {
    setTimeout(() => {
      fetchZones();
    }, 100);
  }, [customerId, page, fetchZones]);

  const handleRefresh = () => {
    setRefreshLoading(true);
    fetchZones();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const filteredZones = zonesData?.data?.filter((customer) =>
    customer?.r_zone_name.toLowerCase()?.includes(query?.toLowerCase())
  );

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckMultipleRows = (event) => {
    const _checked = { ...checked, [event.target.name]: event.target.checked };
    setChecked(_checked);
    if (Object.keys(_checked).filter((uid) => _checked[uid]).length) {
      showDeleteButton(true);
    } else {
      showDeleteButton(false);
    }
  };

  const handleMultipleDelete = () => {
    setDeleteLoading(true);
    setZonesDataLoading(true);
    setRefreshLoading(true);
    Promise.all(
      Object.keys(checked)
        .filter((id) => checked[id] === true)
        .map((id) => {
          return deleteZone(customerId, id);
        })
    )
      .then(() => {
        enqueueSnackbar(
          'selected zones deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        setDeleteLoading(false);
        showDeleteButton(false);
        setZonesDataLoading(false);
        setRefreshLoading(false);
        fetchZones();
        setChecked({});
      })
      .catch((res) => {
        setDeleteLoading(false);
        setZonesDataLoading(false);
        setRefreshLoading(false);
        setChecked({});
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleDelete = (value) => {
    setDeleteLoading(true);
    setZonesDataLoading(true);
    setRefreshLoading(true);
    deleteZone(customerId, value.zone_id)
      .then(() => {
        setDeleteLoading(false);
        setZonesDataLoading(false);
        setRefreshLoading(false);
        fetchZones();
        enqueueSnackbar(
          'selected zones deleted sucessfull',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDeleteLoading(false);
        setZonesDataLoading(false);
        setRefreshLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };


  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={count || 0} />
                {countLoading && (
                  <CircularProgress size={29} className={classes.circularprogress} />
                )}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginTop: '8px' }}> Zones </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                {deleteButton ? (
                  <Tooltip title="Delete">
                    <LoadingButton
                      onClick={confirm(() => handleMultipleDelete(), {
                        title: 'Information',
                        confirmationText: 'Yes',
                        disableOkBtn: false,
                        description: 'Are you sure you want to delete selected Zones?',
                      })}
                      loading={deleteLoading}
                      variant="outlined"
                      sx={{ height: '40px' }}
                    >
                      Delete
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search zones"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1.8 }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {zonesDataLoading && <LinearProgress />}

        <CardContent style={{ padding: '0px' }}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              {filteredZones?.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <NoDataComponent noDataFoundText="Zones" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span />
                      </TableCell>
                      <TableCell style={{ width: '90%' }}>Zone name</TableCell>
                      {/* <TableCell style={{width:'10%'}}>Status</TableCell> */}
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredZones?.map((item) => {
                      return (
                        <TableRow hover key={item.zone_id}>
                          <TableCell>
                            <MultipleCheckbox
                              name={item.zone_id}
                              checked={checked[item.zone_id]}
                              onChange={handleCheckMultipleRows}
                            />
                          </TableCell>
                          <TableCell style={{ width: '90%' }}>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <Box sx={{ ml: 1 }}>
                                <Link color="inherit" variant="subtitle2">
                                  {item.r_zone_name}
                                </Link>
                                <Typography color="textSecondary" variant="body2">
                                  Locations: {item?.location?.length}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>

                          {/* <TableCell>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {filteredZones.locations?.length === 0
                              ? ''
                              : filteredZones.locations?.map((item) => {
                                  return (
                                    <Chip
                                      size="small"
                                      label={item.r_location_name || ''}
                                      key={item._uid}
                                      variant="outlined"
                                    />
                                  );
                                })}
                          </Box>
                        </TableCell> */}

                          <TableCell style={{ width: '10%' }}>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={confirm(() => handleDelete(item), {
                                  title: 'Information',
                                  confirmationText: 'Yes',
                                  disableOkBtn: false,
                                  description: 'Are you sure you want to delete this zone',
                                })}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={3}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={count}
                    labelRowsPerPage=""
                    rowsPerPageOptions={[]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default WithConfirm(ZoneTable);

ZoneTable.propTypes = {
  confirm: PropTypes.func.isRequired,
};