import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getBackupEventsTable } from '../../../API/S3/ConfigEvents';
import { UserContext } from '../../../context/UserContext';
import TooltipRendererForCmdb from '../../../utils/TooltipRendererForCmdb';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from "../../../utils/columnDef";
import { toCamelizeWords } from '../../../utils/formatString';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';
import Backuprendere from './renderer/Backuprendere';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  downHigh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    marginTop: 5,
    textAlign: 'center',
  },
  titleDown: {
    textTransform: 'uppercase',
    color: 'red',
    fontSize: '16px',
    marginLeft: '5px',
  },
  countProgress: {
    position: 'absolute',
    left: 22,
    top: 22,
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  progressStyle: {
    position: 'absolute',
    top: 20,
    left: 14,
  },
  criticalChip: {
    fontSize: '16px',
    width: '150px',
  }
});
// ----------------------------------------------------------------

const ConfigBackupEventsTable = (props) => {
  const classes = useStyles();
  const { locationData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);

   // ----------------------------------------------------------------------

   const isTablet = useMediaQuery('(max-width: 1020px)');
   const isDesktop = useMediaQuery('(min-width: 1440px)');
   const isLargeDesktop = useMediaQuery('(min-width: 2560px)');
 
 
 
   const getFontSize = () => {
     if (isLargeDesktop) {
       return '24px';
     } 
     if (isDesktop) {
       return '20px';
     } 
     if (isTablet) {
       return '14px';
     } 
     return '12px';
     }
 
 
   const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };
 
 
 // ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_id: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-cmdb-events'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_id: _params.context.location_id,
        };
        getBackupEventsTable(customerId, payload).then((failedData) => {
          let lastRow = -1;
          if (failedData.total <= _params.endRow) {
            lastRow = failedData.total;
          }
          setTotalItems(failedData.total);
          if (failedData.length === null || undefined || 0) {
            params.api.showNoRowsOverlay();
          }
          if (failedData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(failedData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch(() => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [customerId, query]);

  const components = useMemo(() => ({
    backuprendere: Backuprendere,
  }), []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
    };
  }, []);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnHeaders = [
    {
      headerName: 'Event Time',
      colId: 'g_created_at',
      // pinned: 'left',
      width: 234,
      order: 10,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.data?.g_created_at;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.g_created_at;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      // pinned: 'left',
      order: 20,
      width: 300,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.r_dvc_name;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      order: 30,
      width: 200,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.network_device1?.l_dvc_typ_name);
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      order: 40,
      width: 200,
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.location?.r_location_name
      },
    },
    {
      headerName: 'Status',
      colId: 'r_scan_status',
      order: 50,
      width: 200,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_scan_status === 1) {
          return (
            <div className={classes.downHigh}>
              <Chip label="Success" color="success" className={classes.criticalChip} />
            </div>
          );
        }
        if (params.data?.r_scan_status === 0) {
          return (
            <div className={classes.downHigh}>
              <Chip label="Failed" color="error" className={classes.criticalChip} />
            </div>
          );
        }
        return null;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      order: 60,
      width: 200,
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.network_device1?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.network_device1?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRendererForCmdb(params)}
                <CopyToClipboard
                  text={params?.data?.network_device1?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.network_device1?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return null;
      },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      hide: 'true',
      order: 70,
      width: 200,
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.r_dvc_mac_address;
      },
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: false,
      filter: false,
      floatingFilter: false,
      order: 80,
      width: 200,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.r_dvc_serial_number;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Details',
      colId: 'r_failed_reason',
      // pinned: 'right',
      order: 90,
      width: 200,
      cellRenderer: 'backuprendere',
      cellStyle: responsiveCellStyle,
    }
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-cmdb-events', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem('nms-cmdb-events', JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs)));
  };


  // ----------------------------------------------------------------------
  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={30} className={classes.progressStyle} />}
                <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  CMDB Scan Events
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={0}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search events"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Box>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      openDialog={openColSettingsDialog}
                      handleClose={() => setOpenColSettingsDialog(false)}
                      columnDefs={columnDefs}
                      gridApi={gridApi}
                      resetColumnSetting={resetColumnSetting}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '550px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            components={components}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
            onDragStopped={onDragStopped}
          />
        </Box>
      </Card>
    </>
  );
};

ConfigBackupEventsTable.propTypes = {
  locationData: PropTypes.array,
};

export default ConfigBackupEventsTable;
