import SourceIcon from '@mui/icons-material/Source';
import { Box, IconButton, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigDialog from './ConfigDialog';


const ConfigRenderer = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const [openChonkyDialog, setOpenChonkyDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenChonkyDialog(true);
  };

  if (props.data === undefined) {
    return '' ;
   
  }

  if (data?.r_s3_folder_path) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <IconButton onClick={handleClickOpen}>
          <SourceIcon />
        </IconButton>

        {openChonkyDialog && (
          <ConfigDialog 
            openDialog={openChonkyDialog} 
            data={data} 
            handleClose={() => {
              navigate('/dashboard/config-change-backup-table');
              setOpenChonkyDialog(false)} 
            } 
          />
        )}
      </Box>
    );
  }
  return 'Backup not yet available';
};


ConfigRenderer.propTypes = {
  data: PropTypes.object,
};

export default ConfigRenderer;
