import { Close } from '@mui/icons-material';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    Slide,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';
import AWS from 'aws-sdk';
import { useSnackbar } from 'notistack';
import { forwardRef, useEffect, useState } from 'react';
import { ACCESS_KEY_ID, BUCKET_NAME, BUCKET_REGION, SECRET_ACCESS_KEY } from '../../../../../API/S3';
import S3Worker from '../../../../CMDBBackups/S3.worker';
//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        display: 'flex'
    },
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    fileNameBox: {
        width: '100%',
        padding: '10px',
        marginBottom: '25px',
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#f5f5f5',
        fontFamily: 'Sans-serif',
    },
    stackContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

//----------------------------------------------------------------------

export default function Backupdialog(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { openDialog, handleClose, data } = props;
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState('');
    const [filePath, setFilePath] = useState(data.r_path);
    const worker = new S3Worker();
    // ------------------------------------------------------
    useEffect(() => {
        if (filePath) {
            worker.onmessage = function (event) {
                setLoading(false);
                setDocs(event.data);
            };
            worker.postMessage({ bucket: BUCKET_NAME, key: filePath });
            return () => {
                worker.terminate();
            };
        }
    }, [filePath]);

    const file = filePath.split('/');
    const fileName = file[4];

    const s3 = new AWS.S3({
        region: BUCKET_REGION,
        credentials: {
            accessKeyId: ACCESS_KEY_ID,
            secretAccessKey: SECRET_ACCESS_KEY,
        },
    });
    const downloadFile = async () => {
        worker.postMessage({ bucket: BUCKET_NAME, key: filePath, action: 'donwload' });
        worker.addEventListener('message', async (event) => {
            const data = event instanceof ReadableStream;
            if (!data) {
                const stream = event.data;
                const file = new Blob([stream], { type: 'text/plain' });
                const element = document.createElement('a');
                element.href = URL.createObjectURL(file);
                const fileName = filePath.split('/').pop();
                element.download = fileName;
                document.body.appendChild(element);
                element.click();
                enqueueSnackbar('Downloaded successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                setTimeout(() => { }, 500);
            } else {
                console.error(event.data.error);
            }
            worker.terminate();
        });
    };


    return (
        <>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody,
                }}
            >
                <DialogTitle>
                    <Stack direction="row" className={classes.stackContainer}>
                        <Box className={classes.root}>
                            {fileName}
                            <Typography variant="subtitle2" gutterBottom className={classes.field}>
                                {filePath}
                                <Tooltip title="Download file">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            // downloadSrcTxtFile(BUCKET_NAME, filePath, fileName);
                                            downloadFile();
                                        }}
                                    >
                                        <DownloadIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Box>

                        <IconButton onClick={handleClose}>
                            <Close sx={{ fontSize: '1.5rem' }} />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                {loading && <LinearProgress />}
                <Divider />

                <DialogContent sx={{ padding: '12px' }}>
                    <pre>
                        {docs}
                    </pre>
                </DialogContent>
            </Dialog>
        </>
    );
}