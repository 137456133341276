/* eslint-disable*/
import { Close, ContentCopy } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getGroupSummaryChangeEventMultiple } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext';
import TooltipRendererForSummary from '../../../utils/TooltipRendererForSummary';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef } from "../../../utils/columnDef";
import { toCamelizeWords } from '../../../utils/formatString';
import { fDateTime } from '../../../utils/formatTime';
import ApproveGroupDialog from './DevicewiseGrpDialogButtons/ApproveGroupDialog';
import HoldtillGroupDialog from './DevicewiseGrpDialogButtons/HoldtillGroupDialog';
import UnapproveGroupDialog from './DevicewiseGrpDialogButtons/UnapproveGroupDialog';
import AffectedActionRenderer from './renderer/AffectedActionRenderer';
// -----------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  cellCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textAlign: 'center',
  },
});

// --------------------------------------------------------------------------
function AffectedDeviceDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { locationData, openDialog, handleClose, groupSummaryEvent, groupedApi } = props;
  const { customerId } = useContext(UserContext);
  const [groupSummary, setGroupSummary] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openUnapproveDialog, setOpenUnapproveDialog] = useState(false);
  const [openHoldTillDialog, setOpenHoldTillDialog] = useState(false);
  const [diffExpanded, setDiffExpanded] = useState(true);
  const [configData, setConfigData] = useState([])


  const toggleDiffExpander = () => {
    setDiffExpanded(!diffExpanded)
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        groupSummaryEvent
      };
      gridApi.purgeInfiniteCache();
    }
  }, [groupSummaryEvent]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-group-summary-change-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  // const onGridReady = useCallback((params) => {
  //   setGridApi(params.api);
  //   return params.api.setDatasource({
  //     rowCount: null,
  //     getRows: async (_params) => {
  //       setLoading(true)
  //       params.api.hideOverlay();
  //       const payload = {
  //         ..._params,
  //         global_keyword: _params.context.global_keyword,
  //         // location_uuids: _params.context.location_uuids,
  //       };
  //       delete payload.context;

  //       const configData = await getGroupSummaryChangeEventMultiple(customerId, _params.context.groupSummaryEvent?.cmdb_group_event_id)
  //         .then((res) => {            
  //           return res;
  //         })
  //         .catch((res) => {
  //           if (res.response.status === 405 || 403 || 500) {
  //             // setAuthorized(false);

  //           }
  //         });
  //       setGroupSummary(configData)         
  //       let lastRow = -1;
  //       if (configData.length <= _params.endRow) {
  //         lastRow = configData.length;
  //       }
  //       if (configData.length === 0) {
  //         params.api.showNoRowsOverlay();
  //       }
  //       _params.successCallback(configData, lastRow);
  //       setLoading(false)
  //     },
  //   });
  // }, []);
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.setDatasource({
      rowCount: null,
      getRows: async (_params) => {
        setLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
        };
        delete payload.context;

        try {
          const response = await getGroupSummaryChangeEventMultiple(customerId, _params.context.groupSummaryEvent?.cmdb_group_event_id);
          const configData = response.data;
          setConfigData(configData)

          if (!configData.length) {
            params.api.showNoRowsOverlay();
          }

          let lastRow = -1;
          if (configData.length <= _params.endRow) {
            lastRow = configData.length;
          }

          _params.successCallback(configData, lastRow);
        } catch (error) {
          console.error('API Call Error:', error); // Log API call errors
          if (error.response && (error.response.status === 405 || 403 || 500)) {
            // Handle unauthorized or server errors
          }
        } finally {
          setLoading(false);
        }
      },
    });
  }, [customerId, groupSummaryEvent]);
  console.log(configData);

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      Width: 230,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,
      sortable: false,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: 'Event Date',
      colId: 'g_created_at',
      width: 225,
      // pinned: 'left',
      filter: false,
      sortable: false,
      floatingFilter: false,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.g_created_at;
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.network_device1?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.network_device1?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRendererForSummary(params)}
                <CopyToClipboard
                  text={params?.data?.network_device1?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.network_device1?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.r_dvc_serial_number;
      },
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.network_device1?.location?.r_location_name;
      },
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.network_device1?.l_dvc_typ_name);
      },
    },
    {
      headerName: 'Status',
      colId: 'r_criticality',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_criticality === 'OPEN') {
          return <Chip label="OPEN" color="error" className={classes.criticalChip} />;
        }
        if (params.data?.r_criticality === 'APPROVED') {
          return <Chip label="APPROVED" color="success" className={classes.criticalChip} />;
        }
        if (params.data?.r_criticality === 'UN_APPROVED') {
          return <Chip label="UN APPROVED" color="info" className={classes.criticalChip} />;
        }
        if (params.data?.r_criticality === 'ON_HOLD') {
          return (
            <Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '10px' }}>
              <Chip label="ON HOLD" color="warning" className={classes.criticalChip} />
              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>
                Till {params.data?.r_criticality_untill}
              </Typography>
            </Box>
          )
        }
      },
    },
    {
      headerName: 'Details',
      colId: 'details',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_criticality_message) {
          return params.data?.r_criticality_message;
        }
        return '';
      },
    },
    {
      headerName: 'Change Since',
      colId: 'r_config_change_since',
      width: 200,
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        let since = '';
        if (params.data?.r_config_change_since) {
          // Format the date as MM/DD/YYYY
          since = moment(params.data.r_config_change_since).format('MM/DD/YYYY');
        }
        return (
          <div className={classes.cellCenter}>
            <Tooltip title={fDateTime(params.data?.r_config_change_since)}>
              <Typography variant={"span"}>{since}</Typography>
            </Tooltip>
          </div>
        );
      },
    },

    {
      headerName: 'Actions',
      colId: 'actions',
      width: 200,
      // pinned: 'right',
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellRenderer: 'affectedActionRenderer',
    },
  ];

  const components = useMemo(() => ({
    affectedActionRenderer: AffectedActionRenderer,
  }), []);

  const handleClickOpenUnapproveDialog = () => {
    setOpenUnapproveDialog(true);
  };

  const handleClickOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  }

  const handleClickOpenHoldtillDialog = () => {
    setOpenHoldTillDialog(true);
  };

  // let decodedHtml = '';

  // try {
  //   const encodedHtml = configData[0]?.r_grp_smry_diff_html;

  //   if (encodedHtml) {
  //     const decodedString = atob(encodedHtml);
  //     decodedHtml = decodeURIComponent(escape(decodedString));
  //   } else {
  //     console.warn("No encoded HTML string provided.");
  //   }
  // } catch (error) {
  //   console.error("Error decoding Base64 string:", error);
  // }

  const [decodedHtml, setDecodedHtml] = useState('');

  useEffect(() => {
    try {
      const encodedHtml = configData[0]?.r_grp_smry_diff_html;
      if (encodedHtml) {
        const decodedString = atob(encodedHtml);
        const decoded = decodeURIComponent(decodedString);
        setDecodedHtml(generateHtmlDiff(decoded));
        console.log(encodedHtml);
        
      } else {
        console.warn("No encoded HTML string provided.");
      }
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
    }
  }, [configData]);

  const generateHtmlDiff = (diffText) => {
    let diffHtml = `
      <style type="text/css">
        .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
        .diff div:hover {background-color:#ffc;}
        .diff .control {background-color: #eaf2f5; color: #999999;}
        .diff .insert {background-color: #ddffdd; color: #000000;}
        .diff .delete {background-color: #ffdddd; color: #000000;}
      </style>
      <div class="diff">
    `;

    diffText.split('\n').forEach((line) => {
      if (line.startsWith('@@')) {
        diffHtml += `<div class="control">${line}</div>`;
      } else if (line.startsWith('+++') || line.startsWith('+')) {
        diffHtml += `<div class="insert">${line}</div>`;
      } else if (line.startsWith('---') || line.startsWith('-')) {
        diffHtml += `<div class="delete">${line}</div>`;
      } else {
        diffHtml += `<div>${line}</div>`;
      }
    });

    diffHtml += '</div>';
    return diffHtml;
  };

  // -------------------------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          Affected Devices
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ padding: '0px' }}>
        <>
          {loading && <LinearProgress />}
          {configData[0]?.r_grp_smry_diff_html ? (
            <Box sx={{ p: 2 }}>
              <Accordion expanded={diffExpanded} onChange={toggleDiffExpander} sx={{ backgroundColor: '#f5f5f5' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Summary Diff</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: 'white', px: 0, py: 0 }}>
                  <Typography>
                    <iframe title="Summary Diff"
                      width="100%"
                      height="200px"
                      style={{border:'1px solid black',padding:'5px'}}
                      srcDoc={decodedHtml} />
                    {/* <div dangerouslySetInnerHTML={{ __html: decodedHtml }} /> */}

                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>)
            : ('')}
          <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
            <AgGridReact
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              {...defaultAgGridProps}
              components={components}
              context={{
                location_uuids: locationData?.data?.map((i) => i._uid),
                groupSummaryEvent
              }}
            />
          </Box>
        </>
      </DialogContent>
      <Divider />

      <DialogActions sx={{ py: 1.7 }}>
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => { handleClickOpenApproveDialog(); }}
        >
          Approve all
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={loading}
          onClick={() => { handleClickOpenUnapproveDialog(); }}
        >
          Un-approve all
        </Button>
        <Button
          variant="contained"
          color="warning"
          disabled={loading}
          onClick={() => { handleClickOpenHoldtillDialog(); }}
        >
          Hold untill all
        </Button>
        {openApproveDialog && (
          <ApproveGroupDialog
            openDialog={openApproveDialog}
            handleClose={() => setOpenApproveDialog(false)}
            data={groupSummary}
            gridApi={gridApi}
            groupedApi={groupedApi}
          />
        )}
        {openUnapproveDialog && (
          <UnapproveGroupDialog
            openDialog={openUnapproveDialog}
            handleClose={() => setOpenUnapproveDialog(false)}
            data={groupSummary}
            gridApi={gridApi}
            groupedApi={groupedApi}
          />
        )}
        {openHoldTillDialog && (
          <HoldtillGroupDialog
            openDialog={openHoldTillDialog}
            handleClose={() => setOpenHoldTillDialog(false)}
            data={groupSummary}
            gridApi={gridApi}
            groupedApi={groupedApi}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
export default AffectedDeviceDialog;
