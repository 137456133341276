import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

// ----------------------firewall - fortinet--------------------------------
// --------------------system-----------------------------------------

export const networkHealthOsVersion = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/os_version`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthVPNClients = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/vpn_client`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthSessions = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/sessions`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthVDOMusage = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/vdom_usage`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  //  const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const networkHealthCpuUsage = async (id,payload) => {
  // -------also used for aruba_os and access_point - cpu utilization
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/cpu_usage`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
//  const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=${payload.start}&to=${payload.end}`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)

const newPro = testbedModem.get(`/api/${id}/network/health_v2/${payload.device_type}/system/get_regular_metrics?from=${payload.start}&to=${payload.end}`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722494979&to=1722506174`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const networkHealthMemoryUsage = async (id, serialNumber, payload) => {
  // -------also used for aruba_os and access_point - memory free
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/memory_usage_latest`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1721914938`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthMemoryUsageDetailed = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/system/memory_usage`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

// --------------------HA Cluster-----------------------------------------


export const haClusterCPUusageRatio = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/cpu_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};


export const haClusterSessions = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/sessions`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterNetworkUsageRatio = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/network_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberPacketsTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_packets_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemoryUsageRatio = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/memory_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberBytesTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_bytes_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberStatus = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterVirusEventsTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/virus_events_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterIPSeventsTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/ips_events_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMEMusageRatio = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/ha_cluster/memory_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// -------------------------Interface -----------------------------------------------------------
export const availabilityStatus = async (id,  payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/interface/throughput_bandwidth`, payload)
  // const newPro = testbedModem.get(`api/2/network/health_v2/30CBC7AEF22B_W5YK020KKW8T/system/get_ping_stats?from=1724335818&to=1724822933`)
    const newPro = testbedModem.get(`/api/${id}/network/health_v2/${payload.device_type}/system/get_ping_stats?from=${payload.start}&to=${payload.end}`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const interfaceThroughputBandwidth = async (id,  payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/interface/throughput_bandwidth`, payload)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_interface_metrics?from=1722344860&to=1722345160000&interface=lo`)
    const newPro = testbedModem.get(`/api/${id}/network/health_v2/${payload.device_type}/system/get_interface_metrics?from=${payload.start}&to=${payload.end}&interface=${payload.interface}`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const interfaceStatus = async (id,  payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/interface/interface_status`, payload)
     const newPro = testbedModem.get(`/api/${id}/network/health_v2/${payload.device_type}/system/get_static_interfaces`)
    //  const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_static_interfaces`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};



// -----------------VPN--------------------------------------------

export const clientVpnConnections = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/vpn/client_vpn_connections`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnBandwidthReceive = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/vpn/bandwidth_receive`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnBandwidthTransmit = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/vpn/bandwidth_transmit`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnStatus = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/vpn/vpn_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// ----------------SD-WAN----------------------------------------------------

export const SdwanVirtualStatus = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualLatency = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_latency`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualSentReceivedTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_packet_sent_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualJitter = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_jitter`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualActiveSessions = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_active_sessions`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualPacketLoss = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_packet_loss`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanAllBandwidths = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/all_bandwidth`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanStateChange = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/sd_wan/state_change`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// --------------------cerificate------------------------------

export const CertificateValidity = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/certificate/certificate_valid`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// ---------------------access point - aruba_os ----------------------------

 // -----------aruba_os - client ---------------------------

export const clientsTable = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/client/client`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const networkTable = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/client/network`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

 // -----------------aruba_os - overview ---------------------------


export const throughputBandwidth = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/interface/throughput_bandwidth`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// export const clientTotal = async (id, serialNumber, payload) => {
//   const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/client/client_total`, payload)
//     .then((response) => {
//       return response.data.data;
//     });
//   return newPro;
// };

export const clientTotal = async () =>{
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1722344860&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  .then((response)=> {
    return response.data;
  });
  return newPro;
}

export const macTotal = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/mac_table_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// export const networkTotal = async (id, serialNumber, payload) => {
//   const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/client/network_total`, payload)
//     .then((response) => {
//       return response.data.data;
//     });
//   return newPro;
// };

export const networkTotal = async () => {
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
// const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  .then((response) => {
    return response.data ;
  });
  return newPro;
}

export const vlanTable = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/vlans`, payload)
  const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const macTable = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/mac_table`, payload)
  const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

 // -----------------aruba_os - radio ---------------------------

 export const radioUtilization = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/radio/utilization`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioTable = async (id, serialNumber, payload) => {
  // const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/radio/utilization`, payload)
  // const newPro = testbedModem.get(`/api/1/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1721914938`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/AP_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Sw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  const newPro = testbedModem.get(`/api/2/network/health_v2/WLC_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
  // const newPro = testbedModem.get(`/api/2/network/health_v2/Fw_W5YK01R7RR4W_30cbc7aef37/system/get_regular_metrics?from=1721914895&to=1722345160000`)
    .then((response) => {
      return response.data;
    });
  return newPro;
};

export const radioNoiseFloor = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/radio/noise_floor`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioRadioPower = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/radio/radio_power`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioRadioChannel = async (id, serialNumber, payload) => {
  const newPro = testbedModem.post(`/${id}/network/health/${serialNumber}/radio/radio_channel`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};


 // ----------------------------switch-------------------------

 export const haTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/members`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};