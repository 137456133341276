import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getMembers = async (id, page, payload) => {
  const newPromise =  testbedModem
    .get(`/api/${id}/members`, payload)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};
export const addMember = async (id,data) => {
  const newPromise =  testbedModem
    .post(`/api/${id}/members`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const deleteMember = async (id,memberId, data) => {
  const newPromise =  testbedModem
  .delete(`/api/${id}/members/${memberId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const editMember = async (id,userId, data) => {
  const newPromise =  testbedModem
  .post(`/api/${id}/members/${userId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

