import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, CircularProgress, Grid, IconButton, Paper, Stack, Toolbar, Tooltip, Typography, useMediaQuery, Button, Card, CardContent } from '@mui/material';
import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, Refresh, } from '@mui/icons-material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { getNetworkStat } from '../../API/NetworkDevices/networkDevices';
import { getUser } from '../../API/User/User';
import AddNewDeviceDialog from '../../components/AddNewDevice/AddNewDeviceDialog';
import DecathlonLogo from '../../components/DecathlonLogo';
import IbusLogo from '../../components/IbusLogo';
import { UserContext } from '../../context/UserContext';
import { EndPointIcon } from '../../icons';
import AccountPopover from './AccountPopover';
import Searchbar from './Searchbar';
import CustomerDropDown from './CustomerDropDown';
import { customer } from '../../API/UserAuth/customer';


const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    width: '100%',
    position: 'relative',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 1),
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '100%', // Fallback width
        [theme.breakpoints.up('xs')]: {
            width: '100px', // xs breakpoint
        },
        [theme.breakpoints.up('sm')]: {
            width: '150px', // sm breakpoint
        },
        [theme.breakpoints.up('md')]: {
            width: '100px', // md breakpoint
        },
        [theme.breakpoints.up('lg')]: {
            width: '100px', // lg breakpoint
        },
        [theme.breakpoints.up('xl')]: {
            width: '150px', // xl breakpoint
        },
        [theme.breakpoints.up('xxl')]: {
            width: '200px', // xxl breakpoint
        },
        height: 'auto', // Maintain aspect ratio
        cursor: (props) => (props.multiCustomerStatus ? 'pointer' : 'default'),
    },
    gridItem: {
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            width: '500px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        [theme.breakpoints.up('md')]: {
            width: '600px',
            marginRight: '38px'
        },
        [theme.breakpoints.up('lg')]: {
            width: '780px',
            marginRight: '20px'
        },
        [theme.breakpoints.up('xl')]: {
            width: '1500px',
        },
        [theme.breakpoints.up('xxl')]: {
            width: '1500px', // xxl breakpoint
        },
    },
    paperalignment: {
        minWidth: '10vw',
        textDecoration: 'none',
        [theme.breakpoints.down('lg')]: {
            minWidth: '8vw',
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '6vw',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '5vw',
        },
    },
    paperalignmentMobile: {
        minWidth: '5vw',
        textDecoration: 'none',
    },
    PaperStyling: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    boxPadding: {
        padding: '10px',
    },
    boxPaddingMobile: {
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardsContainer: {
        display: 'flex',
        gap: 10,
        marginRight: '8px',
        flexWrap: 'wrap',
    },
    cardsContainerMobile: {
        display: 'flex',
        gap: 6,
        marginRight: '8px',
        flexWrap: 'wrap',
    },
    icon: {
        visibility: 'hidden',
    },
    field: {},
    root: {
        padding: '5px',
        '&:hover $cardsContainer $icon': {
            visibility: 'visible',
        },
    },
    deviceUpIconButton: {
        color: 'green',
        background: '#c3e6c3',
        borderRadius: '12px',
        mr: 2,
    },
    deviceDownIconButton: {
        color: 'red',
        background: '#e6b8bf',
        borderRadius: '12px',
        mr: 2,
    },
    endpointsIconButton: {
        color: '#112627',
        background: '#56562f54',
        borderRadius: '12px',
        mr: 2,
    },
    timeIconButton: {
        color: '#000000',
        background: 'rgba(63, 81, 181, 0.5)',
        borderRadius: '12px',
        mr: 2,
    },
    icon: {
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    hoverContainer: {
        '&:hover $icon': {
            opacity: 1,
        },
    },
    loaderBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoBox: {
        padding: '15px',
        display: 'inline-flex',
    },
    logoBoxMobile: {
        padding: '2px',
        display: 'inline-flex',
    },
    gridCardsContainerMobile: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
    },
    gridCardsContainerDesktop: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '5px',
    },
    sidebarIconGridContainerMobile: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'left',
        padding: '5px',
    },
    sidebarIconGridContainerDesktop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        paddingLeft: '5px',
        paddingRight: '15px',
    },
    '@media (max-width: 1020px)': {
        paperalignment: {
            minWidth: '4vw',
        },
        gridCardsContainerDesktop: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            padding: '5px',
        },
        deviceUpIconButton: {
            color: 'green',
            background: '#c3e6c3',
            borderRadius: '8px',
            mr: 1,
        },
        deviceDownIconButton: {
            color: 'red',
            background: '#e6b8bf',
            borderRadius: '8px',
            mr: 1,
        },
        endpointsIconButton: {
            color: '#112627',
            background: '#56562f54',
            borderRadius: '8px',
            mr: 1,
        },
    },
    '@media (min-width: 1024px)': {
        paperalignment: {
            minWidth: '5vw',
        },
    },
    '@media (min-width: 1025px) and (max-width: 1440px)': {
        paperalignment: {
            minWidth: '10vw',
        },
    },
    '@media (min-width: 1441px)': {
        paperalignment: {
            minWidth: '10vw',
        },
    },
}));

const DeviceUp = (props) => <Link to="/dashboard/dashboard/device-up" {...props} />;
const DeviceDown = (props) => <Link to="/dashboard/dashboard/device-down" {...props} />;
const Endpoints = (props) => <Link to="/dashboard/endpoint" {...props} />;

export default function MainDashboardNavbar() {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);

    const [userInfo, setUserInfo] = useState({});
    const [userCustomer, setUserCustomer] = useState({});
    const [networkData, setNetworkData] = useState([]);
    const [cardLoading, setCardLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [online, setOnline] = useState(window.navigator.onLine);
    const onlineRef = useRef(window.navigator.onLine);
    const [openAddDeviceDialog, setOpenAddDeviceDialog] = useState(false);
    const [blobUrl, setBlobUrl] = useState('');
    const refreshStats = useSelector(state => state.statCounter.refreshStats);
    const [currentDateTime, setCurrentDateTime] = useState({
        date: new Date().toLocaleDateString("en-CA"),
        time: new Date().toLocaleTimeString("en-GB"),
    });
    const dispatch = useDispatch();
    const [showCards, setShowCards] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const is1024px = useMediaQuery('(max-width:1024px)');
    const is1440px = useMediaQuery('(min-width:1025px) and (max-width:1440px)');
    const isAbove1440px = useMediaQuery('(min-width:1441px)');

    const getUsers = async () => {
        getUser().then((res) => {
            setUserInfo(res.user);
            setUserCustomer(res.customer);
        });
    };
    useEffect(() => {
        getNetworkData();
        getUsers();
    }, [refreshStats]);

    useEffect(() => {
        if (onlineRef.current) {
            closeSnackbar("online-snackbar")
        } else {
            enqueueSnackbar('You are offline', {
                key: "online-snackbar",
                variant: 'error',
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                action: (key) => (
                    <Refresh onClick={() => { window.location.reload() }} />
                ),
            });
        }
    }, [onlineRef.current]);

    const handleRefresh = () => {
        if (onlineRef.current) {
            dispatch({ type: "TOGGLE" });
        }
    };

    const getNetworkData = useCallback(() => {
        setCardLoading(true);
        setRefreshLoading(true);
        getNetworkStat(customerId)
            .then((res) => {
                setNetworkData(res);
                setCardLoading(false);
                setRefreshLoading(false);
            })
            .catch((err) => {
                setCardLoading(false);
                setRefreshLoading(false);
                enqueueSnackbar('Failed to fetch network data.', { variant: 'error' });
            });
    }, [customerId, enqueueSnackbar]);

    useEffect(() => {
        const isOnline = () => setOnline(true);
        const isOffline = () => setOnline(false);
        window.addEventListener('online', isOnline);
        window.addEventListener('offline', isOffline);

        const interval = setInterval(() => {
            setOnline(window.navigator.onLine);
        }, 1000);
        return () => {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
            clearInterval(interval);
        };
    }, [online]);
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const processLogos = async () => {
            const logos = customers.map(customer => customer.logo);
            if (logos && logos.length > 0) {
                const imageUrls = logos.map(blobString => {
                    try {
                        if (blobString && /^[A-Za-z0-9+/=]+$/.test(blobString)) {
                            const byteCharacters = atob(blobString);
                            const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
                            const blob = new Blob([byteArray], { type: 'image/png' });
                            return URL.createObjectURL(blob);
                        }

                        return null;
                    } catch (error) {
                        return null;
                    }
                }).filter(url => url !== null);
                setImageSrc(imageUrls);
            }
        };
        processLogos();
    }, [customers]);


    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = async () => {
        try {
            const customerData = await customer();
            setCustomers(customerData);
            setOpenModal(true);
            checkCustomerAccess(customerData)
            setShowDropdown(!showDropdown); // Toggle dropdown visibility
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };
    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const customerData = await customer();
                setCustomers(customerData);
                setOpenModal(true);
                checkCustomerAccess(customerData);
            } catch (error) {
                console.error("Error fetching customer data:", error);
            }
        };

        fetchCustomerData();
    }, []);

    const checkCustomerAccess = (response) => {
        if (!response || !Array.isArray(response)) {
            console.error("Invalid response structure:", response);
            return;
        }

        const customerList = response.map(customer => ({
            name: customer.cust_name,
            id: customer.unique_id,
            logo: customer.logo
        }));

        if (customerList.length === 1) {
            navigate('/dashboard/dashboard', { replace: true });
        } else {
            setCustomers(customerList);
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [multiCustomerStatus, setMultiCustomerStatus] = useState(false);

    useEffect(() => {
        const status = localStorage.getItem('multiCustomerStatus');
        if (status === '1') {
            setMultiCustomerStatus(true);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime({
                date: new Date().toLocaleDateString("en-CA"),
                time: new Date().toLocaleTimeString("en-GB"),
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const isSmallScreen = useMediaQuery('(max-width:1024px)');
    const isMediumScreen = useMediaQuery('(min-width:1025px) and (max-width:1441px)');
    const isMdScreen = useMediaQuery(theme => theme.breakpoints.up('lg') && theme.breakpoints.down('xl'));
    let marginValue = '5px';
    if (isSmallScreen) {
        marginValue = '2px';
    } else if (isMediumScreen) {
        marginValue = '5px';
    }

    return (
        <RootStyle>
            <ToolbarStyle>
                <Grid container display='flex'>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: marginValue,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: is1024px ? '5px' : '10px',
                                    marginLeft: isMdScreen ? '38px' : '-2px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                    textDecoration: 'none'
                                }}
                                elevation={5}
                                component={DeviceUp}
                            >
                                <Stack
                                    direction="row"
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >

                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.deviceUpIconButton}
                                        >
                                            {cardLoading ? (
                                                <CircularProgress size={23} />
                                            ) : (
                                                <ArrowCircleUpOutlined />
                                            )}
                                        </IconButton>
                                    </Box>
                                    <Box
                                        style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {!isMdScreen ? "Devices Up" : "Up"}

                                        </Typography>
                                        <Typography
                                            color="green"
                                            variant="subtitle1"
                                            style={{
                                                fontSize: '13px',
                                            }}
                                        >
                                            {networkData && networkData.network_device_up}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: marginValue,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: is1024px ? '5px' : '10px',
                                    marginLeft: isMdScreen ? '38px' : '-20px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                    textDecoration: 'none'
                                }}
                                elevation={5}
                                component={DeviceDown}
                            >
                                <Stack
                                    direction="row"
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >

                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.deviceDownIconButton}
                                        >
                                            {cardLoading ? (
                                                <CircularProgress size={23} />
                                            ) : (
                                                <ArrowCircleUpOutlined />
                                            )}
                                        </IconButton>
                                    </Box>

                                    <Box
                                        style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {!isMdScreen ? "Devices Down" : "Down"}
                                        </Typography>
                                        <Typography
                                            color="error"
                                            variant="subtitle1"
                                            style={{
                                                fontSize: '13px',
                                            }}
                                        >
                                            {networkData && networkData.network_device_down}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: marginValue,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: is1024px ? '5px' : '10px',
                                    // margin: marginValue,
                                    marginLeft: isMdScreen ? '38px' : '-10px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                    textDecoration: 'none'
                                }}
                                elevation={5}
                                component={Endpoints}
                            >
                                <Stack
                                    direction="row"
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.endpointsIconButton}
                                        >
                                            {cardLoading ? (
                                                <CircularProgress size={23} />
                                            ) : (
                                                <ArrowCircleUpOutlined />
                                            )}
                                        </IconButton>
                                    </Box>
                                    <Box
                                        style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {!isMdScreen ? "Client devices" : "Clients"}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontSize: '13px',
                                            }}
                                        >
                                            {networkData && networkData.total_endpoints}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: marginValue,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: is1024px ? '5px' : '10px',
                                    // margin: marginValue,
                                    marginLeft: isMdScreen ? '38px' : '27px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                    textDecoration: 'none'
                                }}
                                elevation={5}
                                component={Endpoints}
                            >
                                <Stack
                                    direction="row"
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.timeIconButton}
                                        >
                                            {cardLoading ? (
                                                <CircularProgress size={23} />
                                            ) : (
                                                <AccessTimeFilledIcon />
                                            )}
                                        </IconButton>
                                    </Box>
                                    <Box
                                        style={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {!isMdScreen ? "Last Updated" : "Updated"}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontSize: '13px',
                                                color: 'blue'
                                            }}
                                        >
                                            {!isMdScreen
                                                ? `${currentDateTime.date} ${currentDateTime.time}`
                                                : currentDateTime.time}                                       
                                                </Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </div>
                    </Grid> */}
                    <Grid className={classes.hoverContainer}>
                        {online && refreshLoading ? (
                            <CircularProgress size={30} className={classes.icon} sx={{ mt: '7px', ml: '-5px' }}/>
                        ) : (
                            <IconButton
                                color="primary"
                                onClick={handleRefresh}
                                sx={{ mt: '7px', ml: '-5px' }}
                                className={classes.icon}
                            >
                                <Refresh sx={{ fontSize: '2rem' }} />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid item className={classes.gridItem}>
                    {multiCustomerStatus && (
                        <>
                            <CustomerDropDown
                                open={openModal}
                                onClose={handleCloseModal}
                                customers={customers}
                                onSelectCustomer={(customer) => {
                                    setOpenModal(false);
                                }}
                            />
                        </>
                    )}
                </Grid>
                {/* <button onClick={multiCustomerStatus ? handleOpenModal : undefined}> */}
                {imageSrc.length > 0 && (
                    <img src={imageSrc[0]} alt="Logo" className={classes.logo} />
                )}
                {/* </button> */}
                <Grid item>
                    <Searchbar />
                </Grid>
                <Grid item>
                    <AccountPopover userInfo={userInfo} />
                </Grid>
            </ToolbarStyle>
        </RootStyle>
    );
}

