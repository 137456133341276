/* eslint-disable*/
import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AWS from 'aws-sdk';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ACCESS_KEY_ID, BUCKET_NAME, BUCKET_REGION, SECRET_ACCESS_KEY } from '../../API/S3';
import S3Worker from './S3.worker';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  icon: {
    // visibility: 'hidden',
  },
  field: {},
  // root: {
  //   '&:hover $field $icon': {
  //     visibility: 'visible',
  //   },
  // },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function CMDBDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, selectedFile } = props;
  const [docs, setDocs] = useState('');
  // const [viewFile, setViewFile] = useState(docs);
  const [loading, setLoading] = useState(true);
  const [filePath, setFilePath] = useState(selectedFile.file.id);
  const worker = new S3Worker();

  function getSignedUrl(bucket, key, expires) {
    const params = {
      Bucket: bucket,
      Key: key,
      Expires: expires,
    };
    return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }

  useEffect(() => {
    if (filePath) {
      worker.onmessage = function (event) {
        setLoading(false);
        setDocs(event.data);       
      };
      worker.postMessage({ bucket: BUCKET_NAME, key: filePath });
      return () => {
        worker.terminate();
      };
    }
  }, [filePath]);
  // ---------------------------------

  const file = filePath.split('/');
  const fileName = file[4];

  const s3 = new AWS.S3({
    region: BUCKET_REGION,
    credentials: {
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY,
    },
  });

  const downloadFile = async () => {
    worker.postMessage({ bucket: BUCKET_NAME, key: filePath, action: 'donwload' });
    worker.addEventListener('message', async (event) => {
      const data = event instanceof ReadableStream;
      if (!data) {
        const stream = event.data;
        const file = new Blob([stream], { type: 'text/plain' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        const fileName = filePath.split('/').pop();
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        enqueueSnackbar('Downloaded successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setTimeout(() => {}, 500);
      } else {
        console.error(event.data.error);
      }
      worker.terminate();
    });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box className={classes.root}>
            {fileName}
            <Typography variant="subtitle2" gutterBottom className={classes.field}>
              {filePath}
              <Tooltip title="Download file">
                <IconButton
                  size="small"
                  onClick={() => {
                    // downloadSrcTxtFile(BUCKET_NAME, filePath, fileName);
                    downloadFile();
                  }}
                >
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />
      {loading && <LinearProgress />}

      <DialogContent>
        <pre>
            {docs}
        </pre>
      </DialogContent>
    </Dialog>
  );
}
export default CMDBDialog;
