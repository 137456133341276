import { TestbedInstance ,instance as modem } from '../modem';

const testbedModem = TestbedInstance();

export const getScheduler = async (id, data) => {
  const newPromise = testbedModem.get(`/api/${id}/schedules`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const updateScheduler = async (id, schedulerId, data) => {
  const newPromise = testbedModem.post(`/api/${id}/schedules/${schedulerId}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getSchedulerEmail = async (id, data) => {
  const newPromise = testbedModem
    .get(`/api/${id}/email-configurations`, data)
    .then((response) => {
      return response.data;
      
    });
  return newPromise;
}

export const addSchedulerEmail = async (customerId, data) => {
  const newPromise = testbedModem
    .post(`/api/${customerId}/email-configurations`, data)
    .then((response) => {
      return response.data;
    })
  return newPromise
};

export const deleteEmail = async (customerId, data) => {
  const newPromise = testbedModem
    .delete(`/api/${customerId}/email-configurations/${data}`, data)
    .then((response) => {
      return response.data;
    })
  return newPromise
};


// export const sendTestEmail = async (customerId) => {
//   const newPromise = modem
//     .delete(`${customerId}/email-test/${data}`, data)
//     .then((response) => {
//       return response.data.data;
//     })
//   return newPromise
// };

