/* eslint-disable*/
import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { addIgnoreRule } from "../../../API/CMDBOperations";
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';


// -----------------------------------------------------------------------
const useStyles = makeStyles({
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },

});

// --------------------------------------------------------------------------
function IgnoreLineDailog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleCloses, gridApi, data } = props;
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [ruleDefaultValue, setRuleDefaultValue] = useState('LINE_CONTAIN');


  const validationSchema = yup.object().shape({
    dcir_device_type: yup.string().required('Device type is required'),
    dcir_rule_type: yup.string().required('Rule type is required'),
    dcir_rule_description: yup.string().required('Rule description is required'),
    dcir_start_with: yup.string().when('dcir_rule_type', {
      is: 'LINES_BETWEEN',
      then: yup.string().required('Start with is required'),
      otherwise: yup.string().nullable(),
    }),
    dcir_ends_with: yup.string().when('dcir_rule_type', {
      is: 'LINES_BETWEEN',
      then: yup.string().required('Ends with is required'),
      otherwise: yup.string().nullable(),
    }),
    dcir_contains: yup.string().when('dcir_rule_type', {
      is: 'LINE_CONTAIN',
      then: yup.string().required('Contains is required'),
      otherwise: yup.string().nullable(),
    }),
    dcir_regex: yup.string().when('dcir_rule_type', {
      is: 'REGEX',
      then: yup.string().required('Regex is required'),
      otherwise: yup.string().nullable(),
    }),
  });

  const defaultValues = {
    dcir_device_type: data?.c_m_d_b_network_device?.r_dvc_type_name || "",
    dcir_rule_type: "LINE_CONTAIN" || "",
    dcir_rule_description: "",
    dcir_start_with: "",
    dcir_ends_with: "",
    dcir_contains: "",
    dcir_regex: ""
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const dcir_rule_type = watch('dcir_rule_type');

  const onSubmit = (data) => {
    setLoading(true);
    addIgnoreRule(customerId, data)
      .then((res) => {
        setLoading(false);
        handleCloses();
        enqueueSnackbar(
          'Rule added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        gridApi?.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        handleCloses();
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };



  return (
    <Dialog
      open={openDialog}
      onClose={handleCloses}
      fullWidth
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Stack direction="row" className={classes.stackContainer}>
            <Box style={{ textAlign: 'left' }}>
              Add ignore rule <br />

            </Box>
            <IconButton disabled={loading} onClick={handleCloses}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Controller
            control={control}
            name="dcir_device_type"
            render={({ field }) => (
              <TextField
                {...field}
                label="Device Type *"
                select
                fullWidth
                InputLabelProps={{ shrink: 'true' }}
                error={!!errors.dcir_device_type}
                helperText={errors.dcir_device_type?.message}
              >
                <MenuItem value={'ACCESS_POINT'}>Access Points</MenuItem>
                <MenuItem value={'FIREWALL'}>Firewall</MenuItem>
                <MenuItem value={'SWITCH'}>Switch</MenuItem>
                <MenuItem value={'WIRELESS_VIRTUAL_CONTROLLER'}>Wireless LAN Controller</MenuItem>
                <MenuItem value={'WIRELESS_MOBILITY_MASTER'}>Wireless Mobility Master</MenuItem>
              </TextField>
            )}
          />
          <RHFTextField
            name="dcir_rule_description"
            label="Rule Description *"
            fullWidth
            error={!!errors.dcir_rule_description}
            helperText={errors.dcir_rule_description?.message}
          />
          <Controller
            control={control}
            name="dcir_rule_type"
            render={({ field }) => (
              <TextField
                {...field}
                label="Rule Type *"
                select
                fullWidth
                InputLabelProps={{ shrink: 'true' }}
                error={!!errors.dcir_rule_type}
                helperText={errors.dcir_rule_type?.message}
              >
                <MenuItem value={'LINES_BETWEEN'}>Lines between</MenuItem>
                <MenuItem value={'LINE_CONTAIN'}>Line contain</MenuItem>
                <MenuItem value={'REGEX'}>Regex</MenuItem>
              </TextField>
            )}
          />

          {['LINES_BETWEEN', 'LINE_CONTAIN', 'REGEX'].includes(dcir_rule_type) && (
            <>
              {dcir_rule_type === 'LINES_BETWEEN' && (
                <>
                  <RHFTextField
                    name="dcir_start_with"
                    label="Start With *"
                    fullWidth
                    error={!!errors.dcir_start_with}
                    helperText={errors.dcir_start_with?.message}
                  />
                  <RHFTextField
                    name="dcir_ends_with"
                    label="Ends With"
                    fullWidth
                    error={!!errors.dcir_ends_with}
                    helperText={errors.dcir_ends_with?.message}
                  />

                </>
              )}

              {dcir_rule_type === 'LINE_CONTAIN' && (
                <RHFTextField
                  name="dcir_contains"
                  label="Contains *"
                  fullWidth
                  error={!!errors.dcir_contains}
                  helperText={errors.dcir_contains?.message}
                />
              )}

              {dcir_rule_type === 'REGEX' && (
                <RHFTextField
                  name="dcir_regex"
                  label="Regex *"
                  fullWidth
                  error={!!errors.dcir_regex}
                  helperText={errors.dcir_regex?.message}
                />
              )}
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={handleCloses}>Cancel</Button>
          <LoadingButton variant="contained" loading={loading} type="submit">
            Add
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
export default IgnoreLineDailog;
