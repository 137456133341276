/* eslint-disable */
import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { getInventory } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext';
import TooltipRenderer from '../../../utils/TooltipRenderer';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { mapColumnDefinitionToStore } from "../../../utils/columnDef";
import { timeSince, toCamelizeWords } from '../../../utils/formatString';
import { fDateTimeSuffix } from '../../../utils/formatTime';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  downHigh: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
    textAlign: 'center'
  },
  titleDown: {
    textTransform: 'uppercase',
    color: 'red',
    fontSize: '16px',
  },
  countProgress: {
    position: 'absolute',
    left: 22,
    top: 22,
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  progressStyle: {
    position: 'absolute',
    left: 27,
    top: 22,
  },
});
// ----------------------------------------------------------------

const DeviceDownInventoryTable = (props) => {
  const { locationData } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);

// -------------------------------------------------------------------------------------

const isTablet = useMediaQuery('(max-width: 1020px)');
const isDesktop = useMediaQuery('(min-width: 1440px)');
const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

const getFontSize = () => {
 if (isLargeDesktop) {
   return '20px';
 } 
 if (isDesktop) {
   return '16px';
 } 
 if (isTablet) {
   return '14px';
 } 
 return '12px';
 }

const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };

// ----------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (gridApi) {
      gridApi.refreshInfiniteCache()
    }
  }, [refreshStats])

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-inventory-device-down'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      const _columnDefs = columnHeaders.reduce((a, b) => {
        const a1 = stored.find(e => e.colId === b.colId);
        return a.concat(Object.assign(b, a1));
      }, []).sort((a, b) => a.order - b.order);
      setColumnDefs(_columnDefs);
    }
  }, []);

  const handleRefresh = () => {
    // dispatch({type:"TOGGLE"})
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
          filterModel: {
            ..._params.filterModel,
            r_dvc_up_down: {
              filterType: 'text',
              type: '=',
              filter: 'DOWN',
            },
          },
        };
        getInventory(customerId, payload).then((inventoryData) => {
          let lastRow = -1;
          if (inventoryData.total <= _params.endRow) {
            lastRow = inventoryData.total;
          }
          setTotalItems(inventoryData.total);
          if (inventoryData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(inventoryData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch((e) => {
            console.log(e, 'error');
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      suppressMenu: true,
      filter: true,
    };
  }, []);

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnHeaders = [
    {
      headerName: '#',
      field: 'No',
      colId: 'No',
      // pinned: 'left',
      width: 90,
      floatingFilter: false,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'IP  Address',
      colId: 'r_dvc_ip_address',
      sortable: true,
      // pinned: 'left',
      width: 180,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_ip_address}
              <span className={classes.field}>
                {TooltipRenderer(params?.data?.r_dvc_mgmt_ip, params?.data?.r_dvc_mgmt_type)}
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      floatingFilter: false,
      // pinned: 'left',
      sortable: false,
      width: 200,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_location_name;
      },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      sortable: true,
      // pinned: 'left',
      width: 180,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_mac_address;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Serial Number',
      colId: 'r_dvc_serial_number',
      sortable: true,
      // pinned: 'left',
      width: 180,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_serial_number;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      sortable: true,
      width: 180,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_name;
      },
      cellStyle: responsiveCellStyle,
    },
    // {
    //   headerName: 'Device Model',
    //   colId: 'r_dvc_model',
    //   sortable: true,
    //   width:150,
    //   floatingFilterComponentParams: {
    //     suppressFilterButton: true,
    //   },
    //   filterParams: {
    //     filterOptions: ['contains'],
    //     defaultOption: 'contains',
    //     suppressAndOrCondition: true,
    //     debounceMs: 300,
    //   },
    //   valueGetter: (params) => {
    //     if (params.data === undefined) {
    //       return '';
    //     }
    //     return params.data?.r_dvc_model;
    //   },
    //   cellStyle: responsiveCellStyle,
    // },
    {
      headerName: 'Down Since',
      colId: 'r_dvc_up_down',
      floatingFilter: false,
      sortable: false,
      filter: false,
      width: 150,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        // return timeSince(params?.data?.r_dvc_last_up_ts * 1000);
        if (params?.data?.r_dvc_up_down?.toLowerCase() === 'down') {
          let since = '';
          if (params?.data?.r_dvc_last_down_ts) {
            since = `About ${timeSince(params?.data?.r_dvc_last_down_ts * 1000)}`;
          }
          return (
            <div className={classes.downHigh}>
              <Tooltip title={fDateTimeSuffix(params?.data?.r_dvc_last_down_ts * 1000)}>
                <Typography>{since}</Typography>
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      headerName: 'Vendor Model',
      colId: 'l_dvc_typ_vendor',
      sortable: false,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        const l_dvc_typ_vendor = params.data?.r_dvc_model?.indexOf(params.data?.l_dvc_typ_vendor) === -1 || !params.data?.r_dvc_model ? params.data?.l_dvc_typ_vendor : ""
        const r_dvc_model = params.data?.r_dvc_model ?? ""
        return `${l_dvc_typ_vendor} ${r_dvc_model}`;
      },
      cellStyle: responsiveCellStyle,
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      sortable: true,
      // pinned: 'right',
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.l_dvc_typ_name);
      },
      cellStyle: responsiveCellStyle,
    },
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  }

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders])
    localStorage.setItem(
      'nms-network-inventory-device-down',
      JSON.stringify(mapColumnDefinitionToStore(columnHeaders)),
    );
    setOpenColSettingsDialog(false)
  }

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-inventory-device-down',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs)),
    );
  }

  // ----------------------------------------------------------------------
  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={35} className={classes.countProgress} />}
                <Typography variant="h6" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  Network Devices:
                  <span className={classes.titleDown}> {totalItems} down</span>
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={0}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    openDialog={openColSettingsDialog}
                    handleClose={() => setOpenColSettingsDialog(false)}
                    columnDefs={columnDefs}
                    gridApi={gridApi}
                    resetColumnSetting={resetColumnSetting}
                  />
                )}
              </Grid>
            </Grid>
          }
        />

        <Divider />

        <Box style={{ height: '550px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onDragStopped={onDragStopped}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
          />
        </Box>
      </Card>
    </>
  );
};
export default DeviceDownInventoryTable;
