import axios from 'axios';
import clientCredentials from "../api_endpoints";

export const logIN = async (payload) => {
  const endpoint = clientCredentials.apiHost;

  try {
    const res = await axios.post(`${endpoint}/auth/login`, payload);
    
    if (res.data.data) {
      localStorage.setItem('AuthorizationToken', JSON.stringify(res.data?.data?.authorization));
      
      if (res.data.data.customers.length === 1) {
        localStorage.setItem('customerId', res.data?.data?.customers[0].unique_id);
        localStorage.setItem('logo', res.data?.data?.customers[0].logo);
        localStorage.setItem('multiCustomerStatus', -1);
      } else {
        localStorage.setItem('multiCustomerStatus', 1);
      }
    }
    
    return res.data.data;
  } catch (error) {
    throw error;
  }
};

// export const getEventsNetworkUpDown = async (id, data) => {
//   const newPro = modem.post(`${id}/events/network-up-down-v2`, data).then((response) => {
//     return response.data.data;
//   });
//   return newPro;
// };

export const loginOtp = async(data)=>{
  const endpoint = clientCredentials.apiHost;
  try {
    const res = await axios.get(`${endpoint}/otp/generate?email=${data}`);
    
    // if (res.data.data) {
    //   localStorage.setItem('AuthorizationToken', JSON.stringify(res.data?.data?.authorization));
      
    //   if (res.data.data.customers.length === 1) {
    //     localStorage.setItem('customerId', res.data?.data?.customers[0].unique_id);
    //     localStorage.setItem('logo', res.data?.data?.customers[0].logo);
    //     localStorage.setItem('multiCustomerStatus', -1);
    //   } else {
    //     localStorage.setItem('multiCustomerStatus', 1);
    //   }
    // }
    
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const verifyOtp = async(data)=>{
  const endpoint = clientCredentials.apiHost;
  try {
    const res = await axios.post(`${endpoint}/otp/verify`,data);
    
    if (res.data.data) {
      localStorage.setItem('AuthorizationToken', JSON.stringify(res.data?.data?.authorization));
      
      if (res.data.data.customers.length === 1) {
        localStorage.setItem('customerId', res.data?.data?.customers[0].unique_id);
        localStorage.setItem('logo', res.data?.data?.customers[0].logo);
        localStorage.setItem('multiCustomerStatus', -1);
      } else {
        localStorage.setItem('multiCustomerStatus', 1);
      }
    }
    
    return res.data.data;
  } catch (error) {
    throw error;
  }
}

