// import * as React from 'react';
// import { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
// import Brightness1Icon from '@mui/icons-material/Brightness1';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
// import Typography from '@mui/material/Typography';
// import {useMediaQuery, useTheme} from '@mui/material';

// export default function CustomerDropDown({ customers, onSelectCustomer }) {
//   const [selectedCustomer, setSelectedCustomer] = useState();
//   const [imageSrc, setImageSrc] = useState([]);
//   const [backdropOpen, setBackdropOpen] = useState(false);

//   // useEffect(() => {
//   //   const processLogos = async () => {
//   //     const logos = customers.map(customer => customer.logo); 
//   //     if (logos && logos.length > 0) {
//   //       const imageUrls = logos.map(blobString => {
//   //         const byteCharacters = atob(blobString);
//   //         const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
//   //         const blob = new Blob([byteArray], { type: 'image/png' });
//   //         return URL.createObjectURL(blob);
//   //       });
//   //       setImageSrc(imageUrls);
//   //     }
//   //     // setLoading(false);
//   //   };

//   //   processLogos();
//   // }, [customers]);

//   useEffect(() => {
//     const processLogos = async () => {
      
//         const logos = customers.map(customer => customer.logo); 
        
//         if (logos && logos.length > 0) {
//             const imageUrls = logos.map(blobString => {
//                 try {
//                     // Validate and decode base64 string
//                     if (blobString && /^[A-Za-z0-9+/=]+$/.test(blobString) ) {
//                         const byteCharacters = atob(blobString);
//                         const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
//                         const blob = new Blob([byteArray], { type: 'image/png' });
//                         return URL.createObjectURL(blob);
//                     }
//                     // No need for else, simply return null if the condition is not met
//                     return null;
//                 } catch (error) {
//                     console.error('Failed to decode base64 string:', error);
//                     return null;
//                 }
//             }).filter(url => url !== null); // Filter out null values
           
//             setImageSrc(imageUrls);
//         }
//     };
//     processLogos();
// }, [customers]);
  

//   useEffect(() => {
//     const activeCustomerId = localStorage.getItem('customerId');
//     if (activeCustomerId) {
//       const activeCustomer = customers.find(customer => String(customer.id) === activeCustomerId);
//       setSelectedCustomer(activeCustomer);
//     }
//   }, [customers]);

//   const handleSelect = (customer) => {
//     setSelectedCustomer(customer);
//     onSelectCustomer(customer);
//     localStorage.setItem('customerId', customer.id);
//     localStorage.setItem('logo', customer.logo);
//     localStorage.setItem('customerName', customer.name);
//     setBackdropOpen(true);
//     setTimeout(() => {
//       window.location.reload();
//     }, 1000);
//   };

//   const isCustomerActive = (customerId) => {
//     return localStorage.getItem('customerId') === String(customerId).trim();
//   };

//   const sortedCustomers = customers.sort((a, b) => {
//     return isCustomerActive(b.id) - isCustomerActive(a.id);
//   });
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
//   return (
//     <>
//       <Grid container justifyContent="center">
//         <Grid item xs={12} sm={10} md={8} lg={6} xl={4} sx={{ marginRight: '-60%', height: '20%' }}>
//           <Autocomplete
//             id="customer-autocomplete"
//             options={sortedCustomers}
//             getOptionLabel={(customer) => customer.name}
//             value={selectedCustomer}
//             onChange={(event, newValue) => {
//               handleSelect(newValue);
//             }}
//             renderOption={(props, customer) => (
//               <li {...props} key={customer.id} style={{ display: 'flex', alignItems: 'center', position: 'relative', padding: '8px', marginRight: '5px' }}>
//                 {!isSmallScreen && imageSrc[customers.indexOf(customer)] && (
//                   <img
//                     src={imageSrc[customers.indexOf(customer)]}
//                     alt="Logo"
//                     style={{ width: '50px', height: 'auto', marginRight: '10px' }}
//                   />
//                 )}
//                 <span style={{fontSize:'13px'}}>{customer.name}</span>
//                 <div
//                   style={{
//                     position: 'absolute',
//                     right: '1px',
//                     top: '50%',
//                     transform: 'translateY(-50%)',
//                     padding: '2px 8px',
//                     borderRadius: '4px',
//                     color: isCustomerActive(customer.id) ? 'green' : 'gray',
//                     fontSize: '10px',
//                   }}
//                 >
//                   {isCustomerActive(customer.id) ? <Brightness1Icon style={{ fontSize: '15px' }} /> : <ArrowCircleRightIcon style={{ fontSize: '15px' }}/>}
//                 </div>
//               </li>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Customer"
//                 sx={{ 
//                   '& .MuiInputBase-root': {
//                     height: '40px',  
//                     paddingRight: '0px',
//                   },
//                 }}
//                 InputProps={{
//                   ...params.InputProps,
//                   startAdornment: selectedCustomer && (
//                     <img
//                       src={imageSrc[customers.indexOf(selectedCustomer)]}
//                       alt="Logo"
//                       style={{ width: '30px', height: 'auto', marginRight: '10px' }}
//                     />
//                   ),
//                 }}
//               />
//             )}
//             clearIcon={null}  
//           />
//           <Backdrop
//             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//             open={backdropOpen}
//           >
//             <CircularProgress color="inherit" />
//             <Typography variant="h6" component="div" sx={{ ml: 2 }}>
//               Switching Customer...
//             </Typography>
//           </Backdrop>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CustomerDropDown({ customers, onSelectCustomer }) {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [imageSrcMap, setImageSrcMap] = useState({});
  const [backdropOpen, setBackdropOpen] = useState(false);
  const navigate = useNavigate(); 

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

 
  useEffect(() => {
    const processLogos = () => {
      const logos = customers?.map((customer) => ({
        id: customer?.id,
        logo: customer?.logo,
      }));

      const logoMap = {};

      logos.forEach(({ id, logo }) => {
        try {
          if (logo && /^[A-Za-z0-9+/=]+$/.test(logo)) {
            const byteCharacters = atob(logo);
            const byteArray = Uint8Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const blob = new Blob([byteArray], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            logoMap[id] = imageUrl;
          }
        } catch (error) {
          console.error('Failed to decode base64 string:', error);
        }
      });

      setImageSrcMap(logoMap);
    };

    processLogos();
  }, [customers]);

  useEffect(() => {
    const activeCustomerId = localStorage.getItem('customerId');
    if (activeCustomerId) {
      const activeCustomer = customers.find((customer) => String(customer.id) === activeCustomerId);
      setSelectedCustomer(activeCustomer);
    }
  }, [customers]);

  
  const handleSelect = (customer) => {
    setSelectedCustomer(customer);
    onSelectCustomer(customer);
    localStorage.setItem('customerId', customer?.id);
    localStorage.setItem('logo', customer?.logo);
    localStorage.setItem('customerName', customer?.name);
    setBackdropOpen(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const isCustomerActive = (customerId) => {
    return localStorage.getItem('customerId') === String(customerId).trim();
  };

  const sortedCustomers = customers.sort((a, b) => isCustomerActive(b.id) - isCustomerActive(a.id));

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4} sx={{ marginRight: '-60%', height: '20%' }}>
          <Autocomplete
            id="customer-autocomplete"
            options={sortedCustomers}
            getOptionLabel={(customer) => customer.name}
            value={selectedCustomer}
            onChange={(event, newValue) => handleSelect(newValue)}
            renderOption={(props, customer) => (
              <li
                {...props}
                key={customer.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  padding: '8px',
                  marginRight: '5px',
                }}
              >
                {/* Render only the logo and customer name */}
                {!isSmallScreen && imageSrcMap[customer.id] && (
                  <img
                    src={imageSrcMap[customer.id]}
                    alt="Logo"
                    style={{ width: '50px', height: 'auto', marginRight: '10px' }}
                  />
                )}
                <span style={{ fontSize: '13px' }}>{customer.name}</span>

                <div
                  style={{
                    position: 'absolute',
                    right: '1px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    color: isCustomerActive(customer.id) ? 'green' : 'gray',
                    fontSize: '10px',
                  }}
                >
                  {isCustomerActive(customer.id) ? (
                    <Brightness1Icon style={{ fontSize: '15px' }} />
                  ) : (
                    <ArrowCircleRightIcon style={{ fontSize: '15px' }} />
                  )}
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px',
                    paddingRight: '0px',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedCustomer && (
                    <img
                      src={imageSrcMap[selectedCustomer.id]}
                      alt="Logo"
                      style={{ width: '30px', height: 'auto', marginRight: '10px' }}
                    />
                  ),
                }}
              />
            )}
            clearIcon={null}
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" sx={{ ml: 2 }}>
              Switching Customer...
            </Typography>
          </Backdrop>
        </Grid>
      </Grid>
    </>
  );
}
