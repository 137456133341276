import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MainChart = ({ seriesData, xAxisData }) => {

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: true,
            },
        },
        xaxis: {
            categories: xAxisData,
            title: {
                text: 'Time',
            },
        },
        title: {
            text: 'Interface Metrics',
            align: 'left',
        },
        stroke: {
            curve: 'smooth',
            width: 2, 
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter(val, { seriesIndex }) {
                    if (seriesIndex === 2) {
                        return val === 1 ? 'Up' : 'Down';
                    }
                    return val;
                },
            },
        },
        yaxis: [
            {
                title: {
                    text: 'InOctet (Data in units)',
                },
                min: 0,
                max: Math.max(...seriesData.find(s => s.name === 'InOctet')?.data) * 1.2,
                labels: {
                    formatter: (val) => val.toFixed(0),
                },
            },
            {
                opposite: true,
                title: {
                    text: 'OutOctet (Data in units)',
                },
                min: 0,
                max: Math.max(...seriesData.find(s => s.name === 'OutOctet')?.data) * 1.2,
                labels: {
                    formatter: (val) => val.toFixed(0),
                },
            },
            {
                opposite: true,
                title: {
                    text: 'Status',
                },
                min: 0,
                max: 4,
                tickAmount: 8,
                labels: {
                    formatter: (val) => (val === 1 ? 'Up' : 'Down'),
                },
            },
        ],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
        colors: ['#F28C28', '#4169E1', '#FF4560'], // hrithik Ensure these colors match your design for InOctet, OutOctet, and Status
        legend: {
            show: true,
            onItemClick: {
                toggleDataSeries: true,
            },
        },
    };

    return (
        <div>
            {seriesData?.length > 0 && xAxisData?.length > 0 && (
                <ReactApexChart series={seriesData} options={chartOptions} type="area" height={590} />
            )}
        </div>
    );
};

export default MainChart;





// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const MainChart = ({ seriesData, xAxisData }) => {
//     const chartOptions = {
//         chart: {
//             type: 'area',
//             height: 350
//         },
//         xaxis: {
//             categories: xAxisData
//         },
//         title: {
//             text: 'Interface Metrics',
//             align: 'left'
//         },
//         stroke: {
//             curve: 'smooth'
//         },
//         markers: {
//             size: 4
//         },
//         dataLabels: {
//             enabled: false
//         },
//         tooltip: {
//             shared: true,
//             intersect: false
//         },
//         yaxis: [
//             {
//                 title: {
//                     text: 'Data (in units)'
//                 }
//             },
//             {
//                 opposite: true,
//                 title: {
//                     text: 'Status'
//                 },
//                 min: 0,
//                 max: 4,
//                 tickAmount: 4,
//                 labels: {
//                     formatter: (val) => (val === 1 ? 'Up' : 'Down')
//                 }
//             }
//         ],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 opacityFrom: 0.7,
//                 opacityTo: 0.9,
//                 stops: [0, 90, 100]
//             }
//         }
//     };

//     return (
//         <div>
//             <ReactApexChart series={seriesData} options={chartOptions} type="area" height={590} />
//         </div>
//     );
// };

// export default MainChart;
