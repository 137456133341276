import {
  Folder, Refresh, Search
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Button,
  Card, CardHeader, Chip,
  CircularProgress,
  Divider, Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { deleteLocation, getLocations } from '../../../API/Locations/showLocations';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { WithConfirm } from '../../WithConfirm';
import AddLocationDialog from './AddLocationDialog';
import UploadCsv from './UploadCsv';
import ActionRenderer from './renderer/ActionsRenderer';
import SubnetRenderer from './renderer/SubnetRenderer';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  CardContent: {
    padding: '0px',
  },
  cardAvatar: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
  },
  circularprogress: {
    position: 'absolute',
    left: 11,
    top: 19,
  },
  cardActionButtons: {
    height: '40px'
  },
  AvatarBox: {
    alignItems: 'center',
    display: 'flex'
  },
  Avatar: {
    height: 42,
    width: 42,
  },
  subnetBox2: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px'
  }
});

// -----------------------------------------------------------------------------------------------------------

const LocationsTable = (props) => {
  const classes = useStyles();
  const { confirm } = props;
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [locationDialog, setLocationDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [deleteButton, showDeleteButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ---------------------------------------------------------------------------------------------------------------------

   // ----------------------------------------------------------------------

   const isTablet = useMediaQuery('(max-width: 1020px)');
   const isDesktop = useMediaQuery('(min-width: 1440px)');
   const isLargeDesktop = useMediaQuery('(min-width: 2560px)');
 
 
 
   const getFontSize = () => {
     if (isLargeDesktop) {
       return '24px';
     } 
     if (isDesktop) {
       return '20px';
     } 
     if (isTablet) {
       return '14px';
     } 
     return '12px';
     }
 
 
   const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };
 
 
 // ----------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (gridApi) {
      gridApi.purgeInfiniteCache();
    }
  }, [gridApi]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();

    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          filterModel: {
            ..._params.filterModel,
          },
          sortModel: {
            ..._params.sortModel,
            g_created_at: {
              colId: 'g_created_at',
              sort: 'asce'
            },
          },
        };
        delete payload.context;
        getLocations(customerId, payload)
          .then((locationData) => {            
            let lastRow = -1;
            if (locationData.meta.total) {
              lastRow = locationData.meta.total;
            }
            setCount(locationData.meta.total);
            if (locationData.meta.total === 0) {
              params.api.showNoRowsOverlay();
            }
            _params.successCallback(locationData?.data, lastRow);
            setRefreshLoading(false);
          })
          .catch(() => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
  }, [customerId, query]);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const handleUploadDialog = () => {
    setUploadDialog(true);
  };

  const handleCheckMultipleRows = () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      showDeleteButton(true);
    } else {
      showDeleteButton(false);
    }
  };

  const handleMultipleDelete = () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length === 0) {
      enqueueSnackbar(
        'Please select at least one location to delete',
        {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
        500
      );
      return;
    }
    const selectedIds = selectedRows.map((row) => row.location_id);
    setDeleteLoading(true);
    setRefreshLoading(true);
    Promise.all(
      selectedIds.map((id) => {
        return deleteLocation(customerId, id);
      })
    )
      .then(() => {
        enqueueSnackbar(
          'Selected locations deleted successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        gridApi?.purgeInfiniteCache();
        gridApi?.deselectAll();
        setDeleteLoading(false);
        showDeleteButton(false);
        setRefreshLoading(false);
      })
      .catch((res) => {
        setDeleteLoading(false);
        setRefreshLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
    subnetRenderer: SubnetRenderer,
  }), []);

  const columnHeaders = [
    {
      headerName: 'Name',
      field: 'r_location_name',
      sortable: true,
      filter: true,
      width: 400,
      checkboxSelection: true,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
      cellRenderer: (params) => {
        if (params.data === undefined) {          
          return '';
        }
        if (params.data) {
          return (
            <Box className={classes.AvatarBox}>
              <Avatar
                className={classes.Avatar}
                src={params.data.profile_photo_url}
                alt="img"
              />
              <Box sx={{ ml: 1 }}>
                <Link color="inherit" variant="subtitle2">
                  {params.data.r_location_name}
                </Link>
                <Typography color="textSecondary" variant="body2">
                  {params.data?.zone?.r_zone_name || '----------'}
                </Typography>
              </Box>
            </Box>
          )
        }
        return '';
      },
    },
    {
      headerName: 'Subnets',
      field: 'subnet',
      sortable: false,
      filter: false,
      width: 710,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1, display: 'flex', alignItems: 'center', justifyContent: 'left', overflow: 'auto', maxHeight: '250px',  },
      cellRenderer: 'subnetRenderer',
    },
    {
      headerName: 'Address',
      field: 'r_location_address',
      sortable: true,
      filter: true,
      width: 250,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
    },
    {
      headerName: 'Contact',
      field: 'r_location_contact',
      sortable: false,
      filter: false,
      width: 175,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
    },
    {
      headerName: 'City',
      field: 'city',
      sortable: false,
      filter: false,
      width: 175,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
    },
    {
      headerName: 'Country',
      field: 'country',
      sortable: false,
      filter: false,
      width: 175,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      width: 260,
      cellStyle: { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1, display: 'flex', alignItems: 'center', justifyContent: 'left' },
      cellRenderer: 'actionRenderer',
      pinned: 'right',  
      lockPosition: true,  
      editable: false,  
      sortable: false,  
      filter: false,  
      resizable: false, 
      suppressMovable: true, 
    }
  ];

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
      sortable: true,
      suppressMenu: true,
      floatingFilter: false,
    };
  }, []);

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={count || 0} />
                {refreshLoading && (
                  <CircularProgress size={30} className={classes.circularprogress} />
                )}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginTop: '8px' }}> Locations </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                {deleteButton ? (
                  <Tooltip title="Delete">
                    <LoadingButton
                      className={classes.cardActionButtons}
                      onClick={confirm(() => handleMultipleDelete(), {
                        title: 'Information',
                        confirmationText: 'Yes',
                        disableOkBtn: false,
                        description: 'Are you sure you want to delete selected Locations',
                      })}
                      loading={deleteLoading}
                      variant="outlined"
                    >
                      Delete
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => handleUploadDialog()}
                  color="primary"
                  className={classes.cardActionButtons}
                  variant="outlined"
                  startIcon={<Folder />}
                >
                  Import file
                </Button>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.cardActionButtons}
                  onClick={() => setLocationDialog(true)}
                >
                  Add location
                </Button>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  placeholder="Search Locations"
                  onChange={handleQueryChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1.8 }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            rowHeight={70}
            columnDefs={columnHeaders}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            components={components}
            {...defaultAgGridProps}
            suppressRowClickSelection
            onSelectionChanged={handleCheckMultipleRows}
            context={{
              global_keyword: query,
            }}
          />
        </Box>
      </Card>

      {uploadDialog && (
        <UploadCsv
          openDialog={uploadDialog}
          gridApi={gridApi}
          handleClose={() => {
            setUploadDialog(false);
          }}
        />
      )}
      {locationDialog && (
        <AddLocationDialog
          openDialog={locationDialog}
          handleClose={() => setLocationDialog(false)}
          gridApi={gridApi}
        />
      )}
    </>
  );
};

export default WithConfirm(LocationsTable);

LocationsTable.propTypes = {
  confirm: PropTypes.func.isRequired,
};