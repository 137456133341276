import axios from 'axios';
import clientCredentials from "../api_endpoints"

export const ForgotPassword = async (payload) => {
  const endpoint = clientCredentials.apiHost
  await axios.post(`${endpoint}/auth/forgot-password`, payload)
    .then((res) => {
      return res.data.data;
    })
    .catch((res) => {
      throw res;
    });
};