import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip, CircularProgress,
  Divider, Grid, IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip, Typography, useTheme,  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { getEndpoints } from '../../../API/Endpoints/showEndpoints';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from "../../../utils/columnDef";
import { toCamelizeWords } from '../../../utils/formatString';
import ColumnSettingsDialog from './ColumnSettings/ColumnSettingsDialog';


// -----------------------------------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 12,
    top: 20,
  },
}));

//----------------------------------------------------------------------
const EndpointTable = (props) => {
  const classes = useStyles();
  const { locationData, endpointOS, setEndpointOS, endpointFamily, setEndpointFamily, endpointVendor, setEndpointVendor } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);

  const theme = useTheme();
const is1024px = useMediaQuery(theme.breakpoints.only('md'));
const is1440px = useMediaQuery('(min-width:1440px) and (max-width:2559px)');
const is2560px = useMediaQuery('(min-width:2560px)');

let fontSize = '12px'; 
if (is1024px) {
  fontSize = '14px';
} else if (is1440px) {
  fontSize = '16px';
} else if (is2560px) {
  fontSize = '20px';
}


  useEffect(() => {
    if (gridApi) {
      gridApi.refreshInfiniteCache()
    }
  }, [refreshStats, gridApi])

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
        endpointOS,
        endpointFamily,
        endpointVendor,
      };
      gridApi?.refreshInfiniteCache();
    }
  }, [locationData, endpointOS, endpointFamily, endpointVendor, gridApi]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-endpoints-inventory-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  const deleteEndpointOS = () => {
    setEndpointOS('');
  };

  const deleteEndpointFamily = () => {
    setEndpointFamily('');
  };

  const deleteEndpointVendor = () => {
    setEndpointVendor('');
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
        endpointOS,
        endpointFamily,
        endpointVendor,
      },

      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
          r_dvc_os: _params.context.r_dvc_os,
          r_dvc_family: _params.context.r_dvc_family,
          l_dvc_typ_vendor: _params.context.l_dvc_typ_vendor,
          filterModel: {
            ..._params.filterModel
          },
        };
        if (_params.context.endpointOS) {
          payload.filterModel.r_dvc_os = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.endpointOS,
          }
        }

        if (_params.context.endpointFamily) {
          payload.filterModel.r_dvc_family = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.endpointFamily,
          }
        }
        if (_params.context.endpointVendor) {
          payload.filterModel.l_dvc_typ_vendor = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.endpointVendor,
          }
        }

        delete payload.context;
        getEndpoints(customerId, payload).then((endpointsData) => {
          let lastRow = -1;
          if (endpointsData.total <= _params.endRow) {
            lastRow = endpointsData.total;
          }
          setTotalItems(endpointsData.total);
          if (endpointsData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(endpointsData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch(() => {
            setRefreshLoading(false);
            _params.failCallback();
          });
      },
    });
}, [customerId, endpointFamily, endpointOS, endpointVendor, query]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      suppressMenu: true,
      filter: true,
    };
  }, []);

  const onCopyText = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'No',
      // pinned: 'left',
      width: 100,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },

    {
      headerName: 'IP Address',
      colId: 'r_dvc_ip_address',
      sortable: true,
      // pinned: 'left',
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_ip_address}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
        return params.data?.r_dvc_ip_address;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Location',
      colId: 'r_location_name',
      // pinned: 'left',
      width: 200,
      floatingFilter: false,
      filter: false,
      sortable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_location_name;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Mac Address',
      colId: 'r_dvc_mac_address',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_mac_address;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Device Name',
      colId: 'r_dvc_name',
      sortable: true,
      // pinned: 'right',
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_name;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Network',
      colId: 'r_dvc_network',
      sortable: false,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_type;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Vendor',
      colId: 'l_dvc_typ_vendor',
      sortable: false,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.l_dvc_typ_vendor;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Operating System',
      colId: 'r_dvc_os',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_os;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Family',
      colId: 'r_dvc_family',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_family;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
  ];

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem(
      'nms-endpoints-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(columnHeaders)),
    );
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-endpoints-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs)),
    );
  }
  // ------------------------------------------------------------------------------------------------

  return (
    <>
      <Card id="ndTable">
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}> Client devices :
                  <span className={classes.titleDown}> {totalItems} </span>
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid container spacing={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'center', paddingTop: '10px' }}>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', gap: 2 }}>
                {endpointOS?.length > 0 ? (
                  <Chip
                    label={toCamelizeWords(endpointOS)}
                    value={endpointOS}
                    variant="outlined"
                    onDelete={deleteEndpointOS}
                  />
                ) : (
                  ''
                )}
                {endpointFamily?.length > 0 ? (
                  <Chip
                    label={toCamelizeWords(endpointFamily)}
                    value={endpointFamily}
                    variant="outlined"
                    onDelete={deleteEndpointFamily}
                  />
                ) : (
                  ''
                )}
                {endpointVendor?.length > 0 ? (
                  <Chip
                    label={toCamelizeWords(endpointVendor)}
                    value={endpointVendor}
                    variant="outlined"
                    onDelete={deleteEndpointVendor}
                  />
                ) : (
                  ''
                )}

                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>

              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Box>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      openDialog={openColSettingsDialog}
                      handleClose={() => setOpenColSettingsDialog(false)}
                      columnDefs={columnDefs}
                      gridApi={gridApi}
                      resetColumnSetting={resetColumnSetting}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onDragStopped={onDragStopped}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
              endpointOS,
              endpointFamily,
              endpointVendor
            }}
          />
        </Box>
      </Card>
    </>
  );
};
export default EndpointTable;

EndpointTable.propTypes = {
  locationData: PropTypes.array,
  setEndpointOS: PropTypes.func,
  setEndpointFamily: PropTypes.func,
  setEndpointVendor: PropTypes.func,
  endpointOS: PropTypes.shape({
      length: PropTypes.number,
  }),
  endpointFamily: PropTypes.shape({
      length: PropTypes.number,
  }),
  endpointVendor: PropTypes.shape({
      length: PropTypes.number,
  }),
};