import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { addLocation } from '../../../API/Locations/showLocations';
import { addZone } from '../../../API/Zones/zones';
import FiletoDownload from '../../../Assets/location_test.csv';
import { UserContext } from '../../../context/UserContext/userContext';
import validateSubnet from '../../../utils/ValidateSubnet';

  // ----------------------------------------------------------------------------------------------------------------------

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-start',
    },
    topPaperScrollBody: {
      verticalAlign: 'top',
    },
  });

  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
  };

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

// ----------------------------------------------------------------------------------------------------------------------

export default function UploadCsv(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose, gridApi } = props;
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // --------------------------------------------------------------------------------------------
  const CSV_SEPARATER = ',';
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: 'UTF-8',
        delimiter: CSV_SEPARATER,
        complete: (results) => {
          setFilteredData(results?.data.filter(item => item?.No?.trim() !== ""))
        },
      });
    });
    setShowText(false);
    setShowTable(true);
  }, []);

  const handleSubmit = async () => {
    const uniqueZoneList = []
    filteredData.forEach((item) => {
      const zone = item?.Zonename?.trim();
      if (uniqueZoneList.indexOf(zone) === -1 && zone !== "") {
        uniqueZoneList.push(zone)
      }
    })

    const zonePromises = uniqueZoneList.map((zoneName) => addZone(customerId, { name: zoneName }))

    Promise.all(zonePromises)
      .then((zoneList) => {
        return zoneList
      }).then((zoneList) => {
        const promisses = filteredData.map((item) => _addLocation(item, zoneList))
        setLoading(true)
        Promise.all(promisses)
          .then(() => {
            gridApi.purgeInfiniteCache();    
            handleClose();
            setLoading(false)
            enqueueSnackbar(
              'Locations added sucessfully',
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          })
          .catch((res) => {
            setLoading(false)
            handleClose();
            enqueueSnackbar(
              res.response.data.message,
              {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          });
      })
  };

  const _addLocation = async (value, zoneList) => {
    const subnetInputs = value?.Subnets?.trim('').split(';')?.filter(item => item?.trim() !== "")
    const subnets = [];
    subnetInputs?.forEach(subnet => {
      subnet = subnet?.trim();
      if (validateSubnet(subnet)) {
        subnets.push(subnet);
      } else {
        enqueueSnackbar(
          `Invalid subnet ${subnet}`,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      }
      return true;
    });
    if (subnets.length === 0) {
      return Promise.resolve();
    }
    const data = {
      name: value.Locationname,
      address: value.Locationaddress,
      contact: value.Locationcontact,
      subnets,
    };

    const zoneName = value?.Zonename?.trim();
    if (zoneName) {
      const zoneuidValue = zoneList?.find(item => item.r_zone_name === zoneName)
      // const zoneData = { name: zoneName };
      // const zoneuidValue = await addZone(customerId, zoneData);
      data.zone_uuid = zoneuidValue._uid;
    }
    return addLocation(customerId, data);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // --------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Upload file here
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive ? 'Drop file here !' : 'Click here or drag and drop the file to upload!'}
            {isDragActive && !isDragReject && 'Drop file here !'}
            {isDragReject && 'File type not supported!'}
          </Box>
          <Box sx={{ py: 2 }}>
            {showText && (
              <Box>
                <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
                  File information will be shown here after the import
                </Typography>
              </Box>
            )}

            {showTable && (
              <Box sx={{ marginTop: '10px' }}>
                <Box>
                  <List key={acceptedFiles.id}>
                    {acceptedFiles.length > 0 &&
                      acceptedFiles.map((acceptedFile, index) => (
                        <Typography key={`files-${index}`}>File name: &ensp; {acceptedFile.name}</Typography>
                      ))}
                  </List>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Location name</TableCell>
                      <TableCell>Subnets</TableCell>
                      <TableCell>Location address</TableCell>
                      <TableCell>Location contact</TableCell>
                      <TableCell>Zone name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData?.map((item) => {
                      return (
                        <TableRow key={item.No}>
                          <TableCell>{item.No}</TableCell>
                          <TableCell>{item.Locationname}</TableCell>
                          <TableCell>{item.Subnets}</TableCell>
                          <TableCell>{item.Locationaddress}</TableCell>
                          <TableCell>{item.Locationcontact}</TableCell>
                          <TableCell>{item.Zonename}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button>
            <a href={FiletoDownload} download="Samplefile.csv">
              Download sample file
            </a>
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="primary" type="submit" onClick={handleSubmit}>
            Save & Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

UploadCsv.propTypes = {
  gridApi: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
