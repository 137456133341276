/* eslint-disable */
import { RestartAlt, Settings, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Panel,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,

} from 'reactflow';
import 'reactflow/dist/style.css';
// import '../../../../styles/sitewise-map-styling.css';
import { getSitewiseTopology } from '../../../../API/NetworkDevices/topology';
import EnterFullscreen from '../../../../Assets/enterFullscreen.svg';
import ExitFullscreen from '../../../../Assets/exitFullscreen.svg';
import sitewiseIcon from '../../../../Assets/sitewiseIcon.png';
import { updateLocation } from '../../../../actions/updateLocation';
import { UserContext } from '../../../../context/UserContext';
import { useFullscreen } from "../../../../hooks/useFullscreen";
import { toCamelizeWords } from '../../../../utils/formatString';
import LocationSelectGraph from '../../LocationSelectGraph';
import PanControl from '../../PanControl';
import { CustomDeviceNode } from './CustomeNodes/CustomDeviceNode';
import FloatingConnectionLine from './FloatingConnectionLine';
import LegendControl from './LegendControl';
import NodeSelector from './NodeSelector';
import SitewiseFloatingEdge from './SitewiseFloatingEdge';
import { useMapSettingContext } from './mapSettingProvider';


const nodeTypes = {
  'ACCESS POINT': CustomDeviceNode,
  'endpoint': CustomDeviceNode,
  'INTERNET': CustomDeviceNode,
  'FIREWALL': CustomDeviceNode,
  'FIREWALL_STACK': CustomDeviceNode,
  'SERVER': CustomDeviceNode,
  'STACK': CustomDeviceNode,
  'SWITCH': CustomDeviceNode,
  'SWITCH_STACK': CustomDeviceNode,
  'SWITCHOSX': CustomDeviceNode,
  'VIRTUAL': CustomDeviceNode,
  'WIRELESS_MOBILITY_MASTER': CustomDeviceNode,
  'WIRELESS_VIRTUAL_CONTROLLER': CustomDeviceNode,
};

const edgeTypes = {
  floating: SitewiseFloatingEdge,
};

const useStyles = makeStyles(() => ({
  overlayCard: {
    width: '300px',
    height: 'auto',
    fontSize: '1.6rem',
    opacity: 1,
  },
  CardItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardText: {
    width: '85%',
    overflow: 'hidden',
  },
  bannerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #eeeeee'
  },
  graphContainer: {
    width: '100%',
    height: '90%',
    backgroundColor: '#f2f2f226',
    overflowY: 'auto',
  },
  panelGridBox: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'right',
    alignItems: 'center',
    padding: '10px',
  },
  panelGridBoxItem: {
    backgroundColor: 'white',
  },
  TypoGridBox1: {
    fontSize: '1.25rem',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    padding: '5px'
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    
  },
  fullScreenCss: {
    height: "100vh",
    background: 'white',
    overflow:'auto',
  },
  notfullScreenCss: {
    height: "-webkit-calc(100vh - 200px)",
    height: "-moz-calc(100vh - 200px)",
    height: "calc(100vh - 200px)",
    background: 'white',
    overflow:'auto',

  },
}));


// -------------------------------------------------------------------------------------------
export default function OverView() {
  const classess = useStyles();
  const location = useLocation();
  const { customerId } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [hoveredEdge, setHoveredEdge] = useState([]);
  const [edgeLabelOnHover, setEdgeLabelOnHover] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState([]);
  const [overallNodes, setOverallNodes] = useNodesState([])
  const [overallEdges, setOverallEdges] = useEdgesState([])
  const [loading, setLoading] = useState(false);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);
  const locationData = useSelector((state) => state.locationSelectReducer.overview.locationData);
  const reactFlowWrapper = useRef(null);
  const [resetLayout, setResetLayout] = useState(false);
  const dispatch = useDispatch();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { mapSettingState, mapSettingDispatch } = useMapSettingContext()
  const fullScreenRef = useRef(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(fullScreenRef);
  const abortControllerRef = useRef(null);

  const onToggleFullscreen = () => {
    toggleFullscreen()
    onFullscreenChange()
    mapSettingDispatch({ type: 'TOGGLE_FULLSCREEN', payload: null })
  }

  const onFullscreenChange = () => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView({ duration: 1000 })
      }, 500);
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  // --------------------------------------------------------------------

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'overview', locationUuid: _locations }));
  };

  const onSelectNode = async (_node) => {
    mapSettingDispatch({ type: 'SET_NODE_SELECTED', payload: _node })
    zoomToNode(_node)
  };

  const zoomToNode = (nodeId) => {
    reactFlowInstance.fitView({ nodes: [{ id: nodeId }], duration: 1000 })
  };

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.locationUuid) {
        dispatch(updateLocation({ page: 'overview', locationUuid: [parsed.locationUuid] }));
      }
    }
  }, [location]);

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleVisibility = (item) => {
    if (hiddenItems.includes(item)) {
      setHiddenItems(hiddenItems.filter((hiddenItem) => hiddenItem !== item));
    } else {
      setHiddenItems([...hiddenItems, item]);
    }
  };

  useEffect(() => {
    setNodes(overallNodes.filter((i) => !hiddenItems.includes(i.type)))
  }, [hiddenItems, availableDeviceTypes]);

  const applyNodes = (_nodes, _edges) => {
    setOverallNodes(_nodes)
    setOverallEdges(_edges)
    setEdges(_edges)
    setAvailableDeviceTypes(
      [
        "INTERNET",
        "FIREWALL",
        "WIRELESS_VIRTUAL_CONTROLLER",
        "SWITCH_STACK",
        "SWITCH",
        "ACCESS POINT",
      ]
    );

  }

  const fetchData = async () => {
    setLoading(true);
    if (!locationData || locationData?.length === 0) {
      applyNodes([], []);
      setLoading(false);
      setResetLayout(false);
      return
    }
    const payload = {
      // endRow: 200,
      // startRow: 0,
    };
    if (locationData.length > 0) {
      payload.location_uuids = locationData;
    }
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const newAbortController = new AbortController();

      abortControllerRef.current = newAbortController;
      const res = await getSitewiseTopology(customerId, payload, newAbortController);
      applyNodes(res.nodes, res.edges);
      setResetLayout(false);
      if (reactFlowInstance) {
        setTimeout(() => {
          reactFlowInstance.fitView({ duration: 1000 })
        }, 500);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResetLayout(false);
    }
  };

  const handleResetLayout = () => {
    setResetLayout(true);
    applyNodes([], [])
    fetchData();
  };

  useEffect(() => {
    fetchData();
    mapSettingDispatch({ type: 'SET_NODE_SELECTED', payload: "" })
  }, [customerId, locationData]);

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (hoveredEdge.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
            },
            style: {
              strokeWidth: 2,
              stroke: "url(#gradient)"
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          style: {},
          data: {
            ..._edge.data,
          },
        }
      })
    }
    )
  }, [hoveredEdge]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const handleNodeHover = (event, node) => {
    const connectedEdges = edges.filter(
      (edge) =>
        edge.type === 'floating' &&
        (edge.source === node.id || edge.target === node.id || edge.source === '0')
    );
    setHoveredEdge(connectedEdges);
  };

  const handleNodeHoverStop = () => {
    setHoveredEdge([]);
  };

  const handleEdgeMouseEnter = (event, edge) => {
    setEdgeLabelOnHover([edge]);
  };

  const handleEdgeMouseLeave = () => {
    setEdgeLabelOnHover([]);
  };

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (edgeLabelOnHover.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
              hoverEdgeLabel: true,
            },
            style: {
              strokeWidth: 3,
              stroke: "url(#gradient)"
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          style: {},
          data: {
            ..._edge.data,
            hoverEdgeLabel: false,
          },
        }
      })
    }
    )
  }, [edgeLabelOnHover]);

  const handleShowHideLabel = () => {
    mapSettingDispatch({ type: 'TOGGLE_SHOW_LABEL' })
  };

  const handleShowLegend = () => {
    mapSettingDispatch({ type: 'TOGGLE_SHOW_LEGEND' })
  };

  const handleShowCounter = () => {
    mapSettingDispatch({ type: 'TOGGLE_COUNTER' })
  };

  const isTablet = useMediaQuery('(max-width: 1020px)');

  const isDesktop = useMediaQuery('(min-width: 1440px)');

  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

  const getFontSize = () => {
    if (isLargeDesktop) {
      return '24px';
    }
    if (isDesktop) {
      return '20px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }

  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };


  // ----------------------------------------------------------------------------------------------
  return (
    <div id="react-flow-sitewise-map" ref={fullScreenRef}>
      <Box className={isFullscreen ? classess.fullScreenCss : classess.notfullScreenCss} >
        <div className={classess.graphContainer} ref={reactFlowWrapper}>
          {loading && <LinearProgress />}
          <ReactFlowProvider>
            <ReactFlow
              fitView
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onNodesChange={onNodesChange}
              onInit={(instance) => setReactFlowInstance(instance)}
              connectionLineComponent={FloatingConnectionLine}
              onEdgeMouseEnter={handleEdgeMouseEnter}
              onEdgeMouseLeave={handleEdgeMouseLeave}
              onNodeMouseEnter={handleNodeHover}
              onNodeMouseLeave={handleNodeHoverStop}
            >

              {mapSettingState.showGridline && (
                <Background variant="dots" gap={12} size={1} />
              )}

              <Panel position="top-left" style={{ backgroundColor: 'white', color: 'black', padding: '10px', marginTop: '0px', marginLeft: '0px' }}>
                <Typography gutterBottom className={classess.TypoGridBox1}>
                  <img width="35px" height="35px" src={sitewiseIcon} alt="sitewiseIcon" />
                  Sitewise Map
                </Typography>
              </Panel>

              <Panel position="top-right" style={{ padding: '10px', marginTop: '0px', marginRight: '0px' }}>
                <Grid container spacing={0} className={classess.panelGridBox}>
                  <Grid item xs="auto" className={classess.panelGridBoxItem}>
                    <LocationSelectGraph selectLocation={selectLocation} locationData={locationData} />
                  </Grid>

                  <Grid item xs="auto" className={classess.panelGridBoxItem}>
                    <Tooltip title="Reset layout">
                      <IconButton onClick={handleResetLayout}>
                        {resetLayout ? (
                          <CircularProgress size={22} sx={{ mr: '3px' }} />
                        ) : (
                          <RestartAlt sx={{ fontSize: '1.8rem' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs="auto" className={classess.panelGridBoxItem}>
                    <Tooltip title="Settings">
                      <IconButton ref={anchorRef} onClick={handleButtonClick}>
                        <Settings sx={{ fontSize: '1.7rem' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {locationData?.length !== 0 && <Grid container spacing={0} className={classess.panelGridBox}>
                  <Grid item className={classess.panelGridBoxItem} >
                    <NodeSelector onSelectNode={onSelectNode} locationData={locationData} />
                  </Grid>
                </Grid>}
                
                <Grid container spacing={0} direction="column" className={classess.panelGridBox}>
                  <Grid item xs="auto" className={classess.panelGridBoxItem}>
                    <Grid item xs="auto" className={classess.panelGridBoxItem}>
                      {mapSettingState.showLegend && <LegendControl />}
                    </Grid>
                  </Grid>
                </Grid>
              </Panel>

              {locationData?.length === 0 && <Box className={classess.bannerContainer}>
                <Typography gutterBottom variant="body2" color="inherit" 
                  sx={{
                    fontSize: {
                      xs: '18px',
                      sm: '26px',
                      md: '30px',
                      lg: '34px',
                      xl: '40px',
                      xxl: '42px',
                    },
                  }}>
                  Please Select location to view site
                </Typography>
              </Box>}

              {locationData?.length !== 0 && nodes?.length === 0 && !loading && <Box className={classess.bannerContainer}>
                <Typography gutterBottom variant="body2" color="inherit" fontSize={40}>
                  Topology data not available for the selected site.
                </Typography>
              </Box>}

              <svg>
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(253,29,29,1)" />
                    <stop offset="100%" stopColor="rgba(131,58,180,1)" />
                  </linearGradient>
                </defs>
              </svg>
             
               <Controls
                className={classess.controls}
                showInteractive 
                position='bottom-left'
                // style={{ marginLeft: '-1005px', marginBottom: '-300px' }}
                >
                {isFullscreen ? (
                  <ControlButton onClick={onToggleFullscreen} title="Exit Fullscreen">
                    <img src={ExitFullscreen} width={15} height={15} alt="Exit Fullscreen" />
                  </ControlButton>
                ) : (
                  <ControlButton onClick={onToggleFullscreen} title="Enter Fullscren">
                    <img src={EnterFullscreen} width={15} height={15} alt="Enter Fullscreen" />
                  </ControlButton>
                )}
              </Controls>
              {/* <Controls className={classess.controls} /> */}
            </ReactFlow>
          </ReactFlowProvider>
        </div>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement='bottom-end'
          disablePortal={true}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classess.overlayCard} variant="outlined">
              {availableDeviceTypes.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                  <Typography className={classess.cardText}>
                    Devices not available
                  </Typography>
                </Box>
              ) : (
                <List>
                  {availableDeviceTypes.map((item, index) => (
                    <ListItem key={index} sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', gap: 3 }}>
                      <ListItemText primary={toCamelizeWords(item)} />
                      <ListItemIcon
                        onClick={() => handleVisibility(item)}
                        sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                      >
                        {hiddenItems.includes(item) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </ListItemIcon>
                    </ListItem>
                  ))}
                  <Divider />

                  <ListItem>
                    <ListItemText primary="Show device name" />
                    <ListItemIcon
                      sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                      onClick={handleShowHideLabel}
                    >
                      {!mapSettingState.showLabel ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemText primary="Show Legend" />
                    <ListItemIcon
                      onClick={handleShowLegend}
                      sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                    >
                      {!mapSettingState.showLegend ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemText primary="Show device counts" />
                    <ListItemIcon
                      onClick={handleShowCounter}
                      sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                    >
                      {!mapSettingState.showCounterIcon ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </ListItemIcon>
                  </ListItem>
                </List>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>

    </div>
  );
}