import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Google as GoogleIcon, Microsoft as MicrosoftIcon, Watch } from '@mui/icons-material'; // Import the icons
import { Grid, Link, Stack, IconButton, InputAdornment, Typography, Button, Box, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ButtonGroup from '@mui/material/ButtonGroup';

// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { logIN, loginOtp, verifyOtp } from '../../../API/UserAuth/login';
// import {loginOtp } from '../../../API/UserAuth/login';
import CustomerSelectionModal from './CustomerSelectionModal';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  // const { loginWithRedirect } = useAuth0();
  const { instance } = useMsal();
  const [showPassword, setShowPassword] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(true);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [PasswordVariant, setPasswordVariant] = useState('contained')
  const [OtpVariant, setOtpVariant] = useState('outlined')
  const [openModal, setOpenModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [timer, setTimer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [otpVerificationStarted, setOtpVerificationStarted] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpRequestLoading, setOtpRequestLoading] = useState(false); // New state
  const [loading, setLoading] = useState(false);




  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().when('isOtpMode', {
      is: false,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired(),
    }),
    otp: Yup.string().when('isOtpMode', {
      is: true,
      then: Yup.string()
        .required('OTP is required')
        // .length(4, 'OTP must be 4 digits'),
        .matches(/^\d{6}$/, 'OTP must be exactly 6 digits'),
      otherwise: Yup.string().notRequired(),
    }),
    isOtpMode: Yup.boolean(),
  });


  const defaultValues = {
    email: '',
    password: '',
    otp: '',
    remember: true,
    isOtpMode: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
    mode: 'onChange',
  });

  // const {
  //   handleSubmit,
  //   getValues,
  //   setValue,
  //   formState: { isSubmitting, errors },
  //   trigger,
  // } = methods;
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
    trigger,
    clearErrors
  } = methods;

  useEffect(() => {
    setValue('isOtpMode', isOtpMode);
  }, [isOtpMode, setValue]);

  useEffect(() => {
    const token = localStorage.getItem('AuthorizationToken');
    const customerId = localStorage.getItem('customerId');

    if (token && customerId) {
      navigate('/dashboard/dashboard', { replace: true });
    }
  }, [navigate]);


  const toggleLoginMode = () => {
    setIsOtpMode((prevMode) => {
      const newMode = !prevMode;
      clearErrors();
      if (newMode) {
        setValue('password', '');
      } else {
        setValue('otp', '');
      }
      return newMode;
    });
  };



  const onSubmit = async (d) => {

    if (isOtpMode && otpSent) {
      const data = {
        email: d.email,
        otp: d.otp,
      };
      try {
        setLoading(true);
        const res = await verifyOtp(data);
        if (res && Object.prototype.hasOwnProperty.call(res, "authorization")) {
          setLoading(false);
          checkCustomerAccess(res)
        }
      }
      catch (error) {
        setLoading(false);
        setErrorMsg(true);
      }
    }
    else {
      const data = {
        email: d.email,
        password: d.password,
      };
      try {
        const res = await logIN(data);
        if (res && Object.prototype.hasOwnProperty.call(res, "authorization")) {
          checkCustomerAccess(res)
        }
      }
      catch (error) {
        setErrorMsg(true);
      }
    }
  };

  const checkCustomerAccess = (response) => {
    const customerList = response.customers.map(customer => ({
      name: customer.cust_name,
      id: customer.unique_id,
      logo: customer.logo
    }));

    if (customerList.length === 1) {

      navigate('/dashboard/dashboard', { replace: true });
    } else {
      setCustomers(customerList);
      setOpenModal(true);
    }
  };
  useEffect(() => {
    let interval;
    if (timeLeft !== null) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    }

    if (timeLeft === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const startTimer = (expTime) => {
    const expirationInSeconds = expTime * 60; // Convert minutes to seconds
    setTimeLeft(expirationInSeconds);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  // const handleRequestOtp = async () => {
  //   const email = getValues('email');

  //   const isEmailValid = await trigger('email');

  //   if (isEmailValid) {
  //     setOtpLoading(true); // Start loading
  //     try {
  //       const res = await loginOtp(email);
  //       setIsOtpMode(true)
  //       setOtpSent(true);
  //       setValue('otp', '');

  //       if (res) {
  //         // console.log(res);
  //         startTimer(res.exp_time); // Start the timer with res.exp_time
  //       }
  //     }
  //     catch (error) {
  //       setIsOtpMode(true)
  //       setErrorMsg(true);
  //     }
  //   }
  //   else {
  //     setIsOtpMode(true)
  //     setErrorMsg(true);
  //   }
  // };

  const handleRequestOtp = async () => {
    const email = getValues('email');
    const isEmailValid = await trigger('email');

    if (isEmailValid) {
      setOtpRequestLoading(true); // Start OTP loading
      try {
        setLoading(true)
        const res = await loginOtp(email);
        setIsOtpMode(true);
        setOtpSent(true);
        setValue('otp', '');

        if (res) {
          startTimer(res.exp_time); // Start the timer with res.exp_time
          setLoading(false)
        }
      } catch (error) {
        setErrorMsg(true);
        setLoading(false)

      } finally {
        setOtpRequestLoading(false); // Stop OTP loading
        setLoading(false)

      }
    } else {
      setErrorMsg(true);
    }
  };

  const onGoogleSubmit = async (d) => {
    const data = {
      email: d.email,
      password: '',
    };
    try {
      const res = await logIN(data);
      if (Object.prototype.hasOwnProperty.call(res, "authorization")) {
        navigate('/dashboard/dashboard', { replace: true });
      }
    } catch (error) {
      setErrorMsg(true);
    }
  };
  const onMicrosoftSubmit = async (d) => {
    const data = {
      email: d.email,
      password: '',
    };
    try {
      const res = await logIN(data);
      if (Object.prototype.hasOwnProperty.call(res, "authorization")) {
        navigate('/dashboard/dashboard', { replace: true });
      }
    } catch (error) {
      setErrorMsg(true);
    }
  }

  const responseMessage = (response) => {
    if (response && response.credential) {
      const decodedToken = jwtDecode(response.credential);
      const email = decodedToken.email;

      if (email) {
        setValue('email', email);
        setShowPasswordField(true);
        onGoogleSubmit({ email });
      } else {
        setErrorMsg(true);
      }
    } else {
      console.error('Google response did not contain a credential:', response);
      setErrorMsg(true);
    }
  };

  const errorMessage = (error) => {
    // console.log(error);
  };

  const handleMicrosoftLogin = () => {
    instance.loginPopup({
      scopes: ["openid", "profile", "User.Read"]
    }).then(response => {
      const email = response.account.username;
      setValue('email', email);
      setShowPasswordField(true);
      onMicrosoftSubmit({ email });
    }).catch(error => {
      console.error(error);
      setErrorMsg(true);
    });
  };


  const handleButtonClick = (e) => {
    const { id } = e.currentTarget;
    setErrorMsg('');

    if (id === 'OTP' && !isOtpMode) {
      // Switch to OTP mode
      toggleLoginMode();
      setOtpVariant("contained");
      setPasswordVariant("outlined");
      setShowPasswordField(false);
      setIsOtpMode(true);
      setValue("password", "");
    } else if (id === 'Password' && isOtpMode) {
      // Switch to Password mode
      toggleLoginMode();
      setPasswordVariant("contained");
      setOtpVariant("outlined");
      setShowPasswordField(true);
      setIsOtpMode(false);
      setOtpSent(false);
      setValue("otp", "");
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: responseMessage,
    onError: errorMessage,
  });

  const handleSubmitOtp = async () => {
    const email = getValues('email');
    const otp = getValues('otp');
    const isEmailValid = await trigger('email');
    const payload = { email, otp };

    if (isEmailValid && otp) {
      try {
        const res = await verifyOtp(payload);
        if (res) {
          console.log(res);
        }
      } catch (error) {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <Box sx={{
      width: '100%',
      maxWidth: { xs: 320, sm: 480, md: 600, lg: 700, xl: 900 },
      mx: 'auto',
      px: 2,
      py: 4,
    }}>
      <Grid>
        <ButtonGroup
          disableElevation
          aria-label="Disabled button group"
        >
          <Button id='Password' variant={PasswordVariant} onClick={handleButtonClick}>Login with Password</Button>
          <Button id='OTP' variant={OtpVariant} onClick={handleButtonClick}>Login with OTP</Button>
        </ButtonGroup>
      </Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!isOtpMode && errorMsg && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            Invalid Username or password
          </Typography>
        )}


        {!isOtpMode && errorMsg && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            {errorMsg}
          </Typography>
        )}
        {isOtpMode && errorMsg && !otpSent && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            Invalid Email Address
          </Typography>
        )}
        {isOtpMode && otpSent && errorMsg && (
          <Typography gutterBottom sx={{ color: 'red' }}>
            Invalid OTP
          </Typography>
        )}
        <Stack spacing={3} sx={{ py: 1, mt: 4 }}>
          {loading && <LinearProgress sx={{ height: '2px' }} />}
          <RHFTextField name="email" label="Email address *" placeholder="Enter your email address" error={!!errors.email} helperText={errors.email ? errors.email.message : ''}
          />
          {showPasswordField && (
            <>
              <RHFTextField
                name="password"
                label="Password *"
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(prev => !prev)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errorMsg && <Typography color="error">Invalid login attempt  </Typography>}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Login
              </LoadingButton>
            </>
          )}
          {/* {isOtpMode && otpSent && (
            <>
              <RHFTextField
                name="otp"
                label="Enter OTP *"
                placeholder="Enter OTP"
                error={!!errors.otp}
                helperText={errors.otp ? errors.otp.message : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleRequestOtp} edge="end">
                        {/* <Iconify icon="eva:refresh-fill" /> */}
          {/* </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isOtpMode && otpSent && !otpVerificationStarted && timeLeft > 0 && (
                <Typography sx={{ mt: 1, color: timeLeft <= 10 ? 'red' : 'textSecondary' }}>
                  OTP expires in: {formatTime(timeLeft)}
                </Typography>
              )}

            </>
          )} */}

          {isOtpMode && otpSent && (
            <>
              <RHFTextField
                name="otp"
                label="Enter OTP *"
                placeholder="Enter OTP"
                error={!!errors.otp}
                helperText={errors.otp ? errors.otp.message : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {timeLeft === 0 && (
                        <IconButton onClick={handleRequestOtp} edge="end">
                          <Iconify icon="eva:refresh-fill" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />


              {isOtpMode && otpSent && !otpVerificationStarted && timeLeft > 0 && (
                <Typography sx={{ mt: 1, color: timeLeft <= 10 ? 'red' : 'textSecondary' }}>
                  OTP expires in: {formatTime(timeLeft)}
                </Typography>
              )}
            </>
          )}

          {!isOtpMode && showPasswordField && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <RHFCheckbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover" href="/forgot-password">
                Forgot password?
              </Link>
            </Stack>
          )}
          {/* {loading && <LinearProgress sx={{ height: '2px' }} />} */}
          {isOtpMode && !otpSent && !showPasswordField && (
            <LoadingButton
              fullWidth
              size="large"
              onClick={handleRequestOtp}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              Receive OTP
            </LoadingButton>
          )}
          {isOtpMode && otpSent && !showPasswordField && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={() => {
                setOtpVerificationStarted(true);
                // handleSubmitOtp(); 
              }}
            >
              Verify OTP
            </LoadingButton>
          )}
          <Grid container item display='flex' justifyContent='center' alignItems='center' mt={3}>
            <Typography>--- Or Login with ---</Typography>
          </Grid>
          {/* <GoogleLogin
            onSuccess={responseMessage}
            onError={errorMessage}
          /> */}
          <Button
            fullWidth
            size="large"
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={() => handleGoogleLogin()}
            sx={{ mt: 2 }}
          >
            Login with Google
          </Button>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            startIcon={<MicrosoftIcon />}
            onClick={handleMicrosoftLogin}
            sx={{ mt: 2 }}
          >
            Login with Microsoft
          </Button>
        </Stack>
      </FormProvider>
      <CustomerSelectionModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        customers={customers}
        onSelectCustomer={(customer) => {
          setOpenModal(false);
          navigate('/dashboard/dashboard', { replace: true });
        }}
      />
    </Box>
  );
}