import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button, Dialog,
  DialogActions, DialogContent, DialogTitle, Divider,
  IconButton, LinearProgress, List, Table, TableBody,
  TableCell,
  TableHead, TableRow, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { addCredentials } from '../../../API/Credentials/listCredentials';
import FiletoDownload from '../../../Assets/Credentials-Sample.csv';
import { UserContext } from '../../../context/UserContext/userContext';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function UploadCredential(props) {
  const classes = useStyles();
  const { openDialog, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false);

  // --------------------------------------------------------------------------------------------
  const CSV_SEPARATER = ',';
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: CSV_SEPARATER,
        encoding: 'UTF-8',
        complete: (results) => {
          setParsedData(results);
          setFilteredData(results?.data)
        },
      })
    })
    setShowText(false);
    setShowTable(true);
  }, [])


  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      await Promise.all(
        filteredData.map(async (item) => {
          await _addCredentials(item);
          return item;
        })
      );
      setLoading(false);
      handleClose();
      enqueueSnackbar('Credentials added successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };


  const _addCredentials = async (value) => {
    let data = {
      type: value.Credential_type,
    };
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV1') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        snmp_string: value.SNMPV1_String,
      }
    }
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV2') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        snmp_community: value.SNMPV2_Community,
      }
    }
    if (value.Credential_type === 'SNMP' && value.SNMP_Version === 'SNMPV3') {
      data = {
        ...data,
        snmp_version: value.SNMP_Version,
        user_snmpv3: value.SNMPV3_User,
        security_level_snmpv3: value.SNMPV3_Security_level,
        auth_type_snmpv3: isEmpty(value.SNMPV3_Auth_Type) ? '' : value.SNMPV3_Auth_Type,
        auth_type_passphrase_snmpv3: isEmpty(value.SNMPV3_Auth_Type_password) ? '' : value.SNMPV3_Auth_Type_password,
        priv_type_snmpv3: isEmpty(value.SNMPV3_Priv_Type) ? '' : value.SNMPV3_Priv_Type,
        priv_type_passphrase_snmpv3: isEmpty(value.SNMPV3_Priv_Type_Password) ? '' : value.SNMPV3_Priv_Type_Password,
      };
    }
    if (value.Credential_type === 'API_KEY') {
      data = {
        ...data,
        api_key: value.APIkey_String,
        api_endpoint: value.API_Endpoint,
      }
    }
    if (value.Credential_type === 'SSH') {
      data = {
        ...data,
        ssh_user: value.SSH_User,
        ssh_password: value.SSH_Password,
        ssh_port: value.SSH_Port,
      }
    }
    return addCredentials(customerId, data);
  };


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
      ],
    },
    useFsAccessApi: false
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  // --------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Upload Credentials File
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        {loading && <LinearProgress />}
        <Divider />
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive ? "Drop file here !" : 'Click here or drag and drop a file to upload!'}
            {isDragActive && !isDragReject && "Drop file here !"}
            {isDragReject && "File type not accepted, sorry!"}
          </Box>
          <Box sx={{ py: 2 }}>
            {showText && <Box>
              <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
                File information will be shown here after the import
              </Typography>
            </Box>}

            {showTable && <Box sx={{ marginTop: '10px' }}>
              <Box>
                <List key={acceptedFiles.id}>
                  {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile, index) => (
                    <Typography key={`files-${index}`}>
                      File name: &ensp; {acceptedFile.name}
                    </Typography>
                  ))}
                </List>
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Credential_type</TableCell>
                    <TableCell>SNMP_Version</TableCell>
                    <TableCell>SNMPV1_String</TableCell>
                    <TableCell>SNMPV2_Community</TableCell>
                    <TableCell>SNMPV3_User</TableCell>
                    <TableCell>SNMPV3_Security_level</TableCell>
                    <TableCell>SNMPV3_Auth_Type</TableCell>
                    <TableCell>SNMPV3_Auth_Type_password</TableCell>
                    <TableCell>SNMPV3_Priv_Type</TableCell>
                    <TableCell>SNMPV3_Priv_Type_Password</TableCell>
                    <TableCell>APIkey_String</TableCell>
                    <TableCell>API_Endpoint</TableCell>
                    <TableCell>SSH_User</TableCell>
                    <TableCell>SSH_Password</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.map((item) => {
                    return (
                      <TableRow key={item.No}>
                        <TableCell>{item.No}</TableCell>
                        <TableCell>{item.Credential_type}</TableCell>
                        <TableCell>{item.SNMP_Version}</TableCell>
                        <TableCell>{item.SNMPV1_String}</TableCell>
                        <TableCell>{item.SNMPV2_Community}</TableCell>
                        <TableCell>{item.SNMPV3_User}</TableCell>
                        <TableCell>{item.SNMPV3_Security_level}</TableCell>
                        <TableCell>{item.SNMPV3_Auth_Type}</TableCell>
                        <TableCell>{item.SNMPV3_Auth_Type_password}</TableCell>
                        <TableCell>{item.SNMPV3_Priv_Type}</TableCell>
                        <TableCell>{item.SNMPV3_Priv_Type_Password}</TableCell>
                        <TableCell>{item.APIkey_String}</TableCell>
                        <TableCell>{item.API_Endpoint}</TableCell>
                        <TableCell>{item.SSH_User}</TableCell>
                        <TableCell>{item.SSH_Password}</TableCell>
                      </TableRow>
                    );
                  })
                  }
                </TableBody>
              </Table>
            </Box>
            }
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button>
            <a href={FiletoDownload} download="Samplefile.csv">
              Download sample file
            </a>
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="contained" color="primary" type="submit" onClick={handleSubmit} loading={loading}>
            Save & Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
