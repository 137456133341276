import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../actions/updateLocation';
import DevicewiseSummaryTable from '../DevicewiseSummary/Components/DevicewiseSummaryTable';
import GroupedSummaryTable from '../GroupedSummary/components/GroupedSummaryTable';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
// -------------------------------------------------------------------------------------------
const useStyles = makeStyles({
  tabs: {
    '& button': {
      border: '1px solid #eeeeee',
      borderRadius: '2px 2px 0px 0px',
      fontSize: '18px',
      padding: '15px',
    },
  },
});
// ----------------------------------------------------------------------------------------------
const SummaryComponents = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.changeevent.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'changeevent', locationUuid: _locations }));
  };
  // -------------------------------------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  // -------------------------------------------------------------------------------------------------------

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} style={{ marginTop: '-64px' }}>
        <LocationSelect selectLocation={selectLocation} locationData={locationData} selectAllLabel="Select all" />
        <Divider sx={{ marginTop: '18px', width: '100%' }} />
      </Grid>


      <Grid item lg={12} sm={12} xs={12} md={12} xl={12} style={{padding:'26px'}}>
        {/* <SummaryTable locationData={locationData} /> */}
        <Card>
          <Tabs value={tabValue} onChange={handleChange} className={classes.tabs}>
            <Tab label="Individual devices" />
            <Tab label="Multiple devices" />
          </Tabs>
          <Divider />
          <TabPanel value={tabValue} index={0}>
            <DevicewiseSummaryTable locationData={locationData} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <GroupedSummaryTable locationData={locationData} />
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SummaryComponents;
